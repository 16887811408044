import moment from 'moment'
export const Apimixin = {
  data() {
    return {
      facilityTypes: [],
      middleFacilityTypes: [],
      facilityTags: [],
      trainDatas: [],
      facilityDatas: [],
      totalFacility: '',
      displayFacility: '',
      photoDataCount: '',
      displayPhoto: '',
      facilityDetail: [],
      facilityAreaList: [],
      usersData: [],
      image: [],
      message: {
        train: '',
        tag: '',
        area: '',
        search: '',
        photo: '',
        facilityDetail: '',
        approval: '',
        approvalFacility: '',
        approvalPhoto: ''
      },
      extension: '',
      mainPhotoName: "",
      types: [],
      allFacilityTypes: [],
    }
  },
  methods: {
    //施設区分情報取得
    getFacilityType(type) {
      let detail = {
        params: {
          apiname: "getClassisication",
        }
      }
      if (typeof type !== 'undefined') {
        detail['params']['type'] = type
      }
      this.axios.post('/api/v1/api', detail).then(response => {
        try {
          if (response.data.body.status == 200) {
            this.allFacilityTypes = response.data.body.response
            for (let i = 0; i < this.allFacilityTypes.length; i++) {
              if (this.allFacilityTypes[i].inParentClassID != 0) {
                this.middleFacilityTypes.push(this.allFacilityTypes[i])
              } else if (this.allFacilityTypes[i].inParentClassID == 0) {
                this.facilityTypes.push(this.allFacilityTypes[i])
              }
            }
            //区分並び替え
            this.facilityTypes.sort(function (a, b) {
              if (a.inSort < b.inSort) return -1;
              if (a.inSort > b.inSort) return 1;
              return 0;
            });
            this.middleFacilityTypes.sort(function (a, b) {
              if (a.inSort < b.inSort) return -1;
              if (a.inSort > b.inSort) return 1;
              return 0;
            });
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設区分ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '施設区分レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      });
    },
    //施設タグ取得
    getTag() {
      if (this.facility.facilityType !== "") {
        let type = ''
        if (this.facility.facilityType.inParentClassID === 0) {
          type = this.facility.facilityType.inClassificationID
        } else {
          type = this.facility.facilityType.inParentClassID
        }
        this.axios.post('/api/v1/api', {
          params: {
            apiname: "getTag",
            type: "relatedClassisication",
            classificationID: type
          }
        }).then(response => {
          try {
            if (response.data.body.status === 200) {
              this.facilityTags = response.data.body.response
            } else {
              this.message.tag = '該当タグがありません。'
              this.facilityTags = []
            }
          } catch (error) {
            console.log(error)
            this.$swal({
              icon: 'error',
              title: '施設タグステータスエラー:' + error,
              confirmButtonText: "閉じる"
            })
            return
          }
        }).catch(error => {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設タグレスポンスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        });
      } else {
        this.facilityTags = []
      }
    },
    //管理画面でのタグ取得
    async getTagAdmin(classID) {
      await this.axios.post('/api/v1/api', {
        params: {
          apiname: "getTag",
          type: "relatedClassisication",
          classificationID: classID
        }
      }).then(response => {
        try {
          if (response.data.body.status === 200) {
            this.facilityTags = response.data.body.response
          } else {
            this.message.tag = '該当タグがありません。'
            this.facilityTags = []
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '管理画面ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => console.log(error))
      this.facilityTags.sort(function (a, b) {
        if (a.inSort < b.inSort) return -1;
        if (a.inSort > b.inSort) return 1;
        return 0;
      });
    },
    // 路線情報取得
    getTrainInfo() {
      this.axios.post('/api/v1/api', {
        params: {
          apiname: "getTrainInfo",
          type: "facilityForm",
          facilityName: this.facility.facilityName
        }
      }).then(response => {
        try {
          if (typeof (response.data.body.response) != "string") {
            this.trainDatas = response.data.body.response
          } else {
            this.message.train = response.data.body.response
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '路線情報ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '路線情報レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //施設一覧全件取得
    getFacilityList(offset, limit) {
      this.axios.post('/api/v1/api', {
        params: {
          apiname: "getFacilityList",
          type: "all",
          limit: limit,
          offset: offset
        }
      }).then(response => {
        try {
          this.facilityDatas = JSON.parse(response.data.body.response)
          if (this.facilityDatas.length !== 0) {
            this.totalFacility = response.data.body.others.count
            this.displayFacility = this.facilityDatas.length
            for (let i = 0; i < this.facilityDatas.length; i++) {
              if (this.facilityDatas[i].boExample === 0) {
                this.facilityDatas[i].vcFacilityDetailName = '代表情報'
              }
              if (this.facilityDatas[i].dtPhotoDate !== null) {
                this.facilityDatas[i].dtPhotoDate = this.facilityDatas[i].dtPhotoDate.split('T')[0];
              } else {
                this.facilityDatas[i].dtPhotoDate = '未撮影'
              }
              if (this.facilityDatas[i].dtNextShootingDate !== null) {
                this.facilityDatas[i].dtNextShootingDate = this.facilityDatas[i].dtNextShootingDate.split('T')[0];
              } else {
                this.facilityDatas[i].dtNextShootingDate = '未撮影'
              }
              if (this.facilityDatas[i].vcStorage !== null) {
                this.facilityDatas[i].vcStorage = this.resizeImgURL + this.facilityDatas[i].vcStorage
              } else {
                this.facilityDatas[i].vcStorage = this.noImage
              }
              if (this.facilityDatas[i].datediff === null) {
                this.facilityDatas[i].datediff = 0
              }
              if (this.facilityDatas[i].vcUserLName === null) {
                this.facilityDatas[i].vcUserLName = '未撮影'
              }
              if (this.facilityDatas[i].inApproval === 0) {
                this.facilityDatas[i].inApproval = "未承認";
              } else if (this.facilityDatas[i].inApproval === 1) {
                this.facilityDatas[i].inApproval = "承認";
              } else if (this.facilityDatas[i].inApproval === 2) {
                this.facilityDatas[i].inApproval = "差し戻し";
              }
            }
          } else {
            this.message.search = "該当の施設が見つかりませんでした。";
            this.totalFacility = 0;
            this.displayFacility = 0;
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設一覧ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '施設一覧レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //施設絞り込み表示API
    searchFacility(offset, limit, searchTheme) {
      console.log(searchTheme)
      this.message.search = ""
      let search = {
        params: {
          apiname: "getFacilityList",
          type: "search",
          limit: limit,
          offset: offset
        }
      }
      //施設区分検索
      if (searchTheme.facilityType !== '') {
        search['params']["classificationID"] = searchTheme.facilityType
      }
      //施設区分検索
      if (searchTheme.middleFacilityType !== '' && searchTheme.facilityType !== '') {
        search['params']["classificationID"] = searchTheme.middleFacilityType
      }
      //撮影者名検索
      if (searchTheme.photoGrapherID !== '') {
        search['params']["photographerID"] = searchTheme.photoGrapherID
      }
      //施設名検索
      if (searchTheme.keyword !== '') {
        search['params']["keyword"] = searchTheme.keyword
      }
      //住所検索
      if (searchTheme.address !== '') {
        search['params']["addsearch"] = searchTheme.address
      }
      //登録日検索
      if (searchTheme.searchFrom !== '') {
        let searchFrom = moment(searchTheme.searchFrom)
        searchFrom = moment(searchFrom).format('YYYYMMDD');
        search['params']["searchFrom"] = searchFrom
      }
      if (searchTheme.searchTo !== '') {
        let searchTo = moment(searchTheme.searchTo).add({
          days: 1
        })
        searchTo = moment(searchTheme.searchTo).format('YYYYMMDD');
        search['params']["searchTo"] = searchTo
      }
      //並び替え
      if (searchTheme.sort !== '') {
        search['params']["sort"] = searchTheme.sort
      }
      //未撮影施設を含むか
      if (searchTheme.unregistered === true) {
        search['params']["Unregistered"] = ''
      }
      this.axios.post('/api/v1/api', search).then(response => {
        // console.log(response)
        try {
          this.facilityDatas = JSON.parse(response.data.body.response)
          if (response.data.body.others.count !== 0) {
            this.displayFacility = this.facilityDatas.length
            this.totalFacility = response.data.body.others.count
            for (let i = 0; i < this.facilityDatas.length; i++) {
              if (this.facilityDatas[i].boExample === 0) {
                this.facilityDatas[i].vcFacilityDetailName = '代表情報'
              }
              if (this.facilityDatas[i].dtPhotoDate !== null) {
                this.facilityDatas[i].dtPhotoDate = this.facilityDatas[i].dtPhotoDate.split('T')[0];
              } else {
                this.facilityDatas[i].dtPhotoDate = '未撮影'
              }
              if (this.facilityDatas[i].dtNextShootingDate !== null) {
                this.facilityDatas[i].dtNextShootingDate = this.facilityDatas[i].dtNextShootingDate.split('T')[0];
              } else {
                this.facilityDatas[i].dtNextShootingDate = '0000-00-00'
              }
              if (this.facilityDatas[i].datediff === null) {
                this.facilityDatas[i].datediff = 0
              }
              if (this.facilityDatas[i].vcUserLName === null) {
                this.facilityDatas[i].vcUserLName = '未撮影'
              }
              if (this.facilityDatas[i].vcStorage !== null) {
                this.facilityDatas[i].vcStorage = this.resizeImgURL + this.facilityDatas[i].vcStorage;
              } else {
                this.facilityDatas[i].vcStorage = this.noImage
              }
              if (this.facilityDatas[i].inApproval === 0) {
                this.facilityDatas[i].inApproval = "未承認";
              } else if (this.facilityDatas[i].inApproval === 1) {
                this.facilityDatas[i].inApproval = "承認";
              } else if (this.facilityDatas[i].inApproval === 2) {
                this.facilityDatas[i].inApproval = "差し戻し";
              }
            }
          } else {
            this.message.search = "該当の施設が見つかりませんでした"
            this.totalFacility = 0
            this.displayFacility = 0
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設絞り込みステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '施設絞り込みレスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //ユーザー情報取得API
    async getUsers(userID) {
      await this.axios.post('/api/v1/api', {
        params: {
          apiname: "getUser",
          uid: userID
        }
      }).then(response => {
        try {
          if (response.data.body.status === 200) {
            this.usersData = response.data.body.response
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: 'ユーザー情報ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: 'ユーザー情報レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //施設選択モーダル近辺エリア取得API
    async getFacilityAreaList(typeID, lat, lng) {
      await this.axios.post('/api/v1/api', {
        params: {
          apiname: "getFacilityList",
          type: "photo",
          classID: typeID,
          lat: lat,
          lon: lng
        }
      }).then(response => {
        // console.log(response)
        try {
          if (response.data.body.result) {
            this.facilityAreaList = response.data.body.response
          } else {
            this.message.area = response.data.body.response
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '近辺エリアステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '近辺エリアレスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //施設詳細情報取得API
    getFacilityDetail(facilityID, type) {
      console.log('getFacilityDetail')
      this.axios.post('/api/v1/api', {
        params: {
          apiname: "getFacilityDetail",
          facilityID: facilityID,
          type: type
        }
      }).then(response => {
        try {
          if (response.data.body.status === 200) {
            console.log(response)
            this.facilityDetail = response.data.body.response
          } else {
            this.message.facilityDetail = response.data.body.response
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設詳細ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '施設詳細レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      });
    },
    //既存施設への写真投稿
    async addPhoto(detailID, facilityName, s3, s3Bucket) {
      console.log('addPhoto')
      if (this.photo.uploadFile.type === "image/jpeg") {
        this.extension = ".jpeg"
        this.imageType = 'image/jpeg'
      } else if (this.photo.uploadFile.type === "image/jpg") {
        this.extension = ".jpg"
        this.imageType = 'image/jpg'
      }
      let date = moment().format("YYYYMMDD_HH:mm:SS")
      this.mainPhotoName = [facilityName.replace(/\+|\]|\[|\?|%|#|\s+|\//gi, '_'), date, 1, ].join('_')
      let message = []
      let result = ''
      await s3.putObject({
        Bucket: s3Bucket,
        Key: this.putImgKey + this.mainPhotoName + this.extension,
        Body: this.photo.uploadFile,
        ContentType: this.imageType
      }).promise().then(response => {
        console.log(response)
        if (response.length !== 0) {
          result = true
          message.push("写真のアップロードが完了しました")
          return result
        } else {
          result = false
          message.push('写真のアップロードに失敗しました')
          return result
        }
      }).catch(error => {
        console.log(error)
        result = false
        this.$swal({
          icon: 'error',
          html: `写真のアップロードに失敗しました`,
          title: '写真登録エラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      });
      await s3.putObject({
        Bucket: s3Bucket,
        Key: this.imgURL + this.mainPhotoName + this.extension,
        Body: this.photo.uploadFile,
        ContentType: this.imageType
      }).promise().then(response => {
        console.log(response)
        if (response.length !== 0) {
          result = true
          message.push("写真のアップロードが完了しました")
          return result
        } else {
          result = false
          message.push('写真のアップロードに失敗しました')
          return result
        }
      }).catch(error => {
        console.log(error)
        result = false
        this.$swal({
          icon: 'error',
          html: `写真のアップロードに失敗しました`,
          title: '既存施設写真登録エラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      });
      await s3.putObject({
        Bucket: s3Bucket,
        Key: this.resizeImgKey + this.mainPhotoName + this.extension,
        Body: this.photo.uploadFile,
        ContentType: this.imageType
      }).promise().then(response => {
        console.log(response)
        if (response.length !== 0) {
          result = true
          message.push("写真のアップロードが完了しました")
          return result
        } else {
          result = false
          message.push('写真のアップロードに失敗しました')
          return result
        }
      }).catch(error => {
        console.log(error)
        result = false
        this.$swal({
          icon: 'error',
          html: `写真のアップロードに失敗しました`,
          title: '既存施設写真登録エラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      });
      if (result) {
        if (this.details.length !== 0) {
          for (let i = 0; i < this.details.length; i++) {
            if (this.details[i].uploadFile.length !== 0) {
              await s3.putObject({
                Bucket: s3Bucket,
                Key: this.putImgKey + this.detailPhotoName[i] + this.extension,
                Body: this.details[i].uploadFile,
                ContentType: this.imageType
              }).promise().then(response => {
                console.log(response)
                console.log("putImgKey: "+ this.putImgKey)
                if (response.length !== 0) {
                  result = true;
                  return result
                } else {
                  result = false
                  return result
                }
              }).catch(error => {
                result = false
                console.log(error)
                this.$swal({
                  icon: 'error',
                  html: `付帯情報の写真アップロードに失敗しました`,
                  title: '写真登録エラー:' + error,
                  confirmButtonText: "閉じる"
                })
                return
              });
              await s3.putObject({
                Bucket: s3Bucket,
                Key: this.imgURL + this.detailPhotoName[i] + this.extension,
                Body: this.details[i].uploadFile,
                ContentType: this.imageType
              }).promise().then(response => {
                console.log(response)
                console.log("imgURL: "+ this.imgURL)
                if (response.length !== 0) {
                  result = true;
                  return result
                } else {
                  result = false
                  return result
                }
              }).catch(error => {
                result = false
                console.log(error)
                this.$swal({
                  icon: 'error',
                  html: `付帯情報の写真アップロードに失敗しました`,
                  title: '既存施設写真登録エラー:' + error,
                  confirmButtonText: "閉じる"
                })
                return
              });
              await s3.putObject({
                Bucket: s3Bucket,
                Key: this.resizeImgKey + this.detailPhotoName[i] + this.extension,
                Body: this.details[i].uploadFile,
                ContentType: this.imageType
              }).promise().then(response => {
                console.log(response)
                console.log("resizeImgKey: "+ this.resizeImgKey)
                if (response.length !== 0) {
                  result = true;
                  return result
                } else {
                  result = false
                  return result
                }
              }).catch(error => {
                result = false
                console.log(error)
                this.$swal({
                  icon: 'error',
                  html: `付帯情報の写真アップロードに失敗しました`,
                  title: '既存施設写真登録エラー:' + error,
                  confirmButtonText: "閉じる"
                })
                return
              });
            }
          }
        }
      }
      if (result) {
        let detail = {
          params: {
            apiname: "postPhoto",
            type: "addPhoto",
            uid: sessionStorage.getItem('userId'),
            detailIDs: detailID,
            photoDatas: {
              0: {
                position: 0,
                imgName: this.mainPhotoName + this.extension,
                releaseTo: this.photo.daterange.start,
                releaseFrom: this.photo.daterange.end,
                photographerID: sessionStorage.getItem('userId'),
                photoData: this.photo.shootDate,
                nextShootingDate: this.photo.nextShoot,
                shootingSeaaon: this.photo.season,
                shootingTime: this.photo.time,
                note: this.photo.remarks
              }
            },
          },
        }
        if (this.details.length !== 0) {
          for (let i = 0; i < this.details.length; i++) {
            if (this.details[i].uploadFile.length !== 0) {
              detail['params']['photoDatas'][i + 1] = {
                position: i + 1,
                imgName: this.detailPhotoName[i] + this.extension,
                releaseTo: this.details[i].daterange.start,
                releaseFrom: this.details[i].daterange.end,
                photographerID: sessionStorage.getItem('userId'),
                photoData: this.details[i].shootDate,
                nextShootingDate: this.details[i].nextShoot,
                shootingSeaaon: this.details[i].season,
                shootingTime: this.details[i].time,
                note: this.details[i].remarks
              }
            }
          }
        }
        await this.axios.post('/api/v1/api', detail).then(response => {
          console.log(response)
          try {
            if (response.data.body.status === 200) {
              message.push(response.data.body.response.message)
              this.message.photo = message
              this.$swal({
                allowOutsideClick: false,
                icon: 'success',
                html: `<b>${message[0]}</b><br><b>${message[1]}</b>`,
                showCancelButton: true,
                confirmButtonText: 'Topページに戻る',
                cancelButtonText: '続けて写真を登録する'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push('/mypage')
                } else {
                  this.photo.image = '';
                  this.photo.imageName = '';
                  this.photo.uploadFile = '';
                  this.photo.shootDate = '';
                  this.photo.nextShoot = '';
                  this.photo.daterange.start = '';
                  this.photo.daterange.end = '';
                  this.photo.season = '';
                  this.photo.time = '';
                  this.facility.lat = '';
                  this.facility.lng = '';
                  this.facility.address = '';
                }
              })
            } else {
              message.push(response.data.body.response.message)
              this.message.photo = message
              this.$swal({
                icon: 'error',
                title: message,
                confirmButtonText: "閉じる"
              })
            }
          } catch (error) {
            console.log(error)
            this.$swal({
              icon: 'error',
              title: '既存施設ステータスエラー:' + error,
              confirmButtonText: "閉じる"
            })
            return
          }
        }).catch(error => {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '既存施設レスポンスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        })
      }
    },
    //施設、写真新規登録
    async newFacilityPhoto(s3,s3Bucket) {
      console.log('newFacilityPhoto')
      let result = false
      let message = []
      await s3.putObject({
        Bucket: s3Bucket,
        Key: this.putImgKey + this.mainPhotoName + this.extension,
        Body: this.photo.uploadFile,
        ContentType: this.imageType
      }).promise().then(response => {
        console.log(response)
        console.log("putImgKey: "+ this.putImgKey)
        if (response.length != 0) {
          result = true
          return result
        } else {
          result = false
          return result
        }
      }).catch(error => {
        result = false
        console.log(error)
        this.$swal({
          icon: 'error',
          html: `写真のアップロードに失敗しました`,
          title: '新規写真登録エラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      });
      await s3.putObject({
        Bucket: s3Bucket,
        Key: this.imgURL + this.mainPhotoName + this.extension,
        Body: this.photo.uploadFile,
        ContentType: this.imageType
      }).promise().then(response => {
        console.log(response)
        console.log("imgURL: "+ this.imgURL)
        if (response.length != 0) {
          result = true
          return result
        } else {
          result = false
          return result
        }
      }).catch(error => {
        result = false
        console.log(error)
        this.$swal({
          icon: 'error',
          html: `写真のアップロードに失敗しました`,
          title: '新規写真登録エラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      });
      await s3.putObject({
        Bucket: s3Bucket,
        Key: this.resizeImgKey + this.mainPhotoName + this.extension,
        Body: this.photo.uploadFile,
        ContentType: this.imageType
      }).promise().then(response => {
        console.log(response)
        console.log("resizeImgKey: "+ this.resizeImgKey)
        if (response.length != 0) {
          result = true
          return result
        } else {
          result = false
          return result
        }
      }).catch(error => {
        result = false
        console.log(error)
        this.$swal({
          icon: 'error',
          html: `写真のアップロードに失敗しました`,
          title: '新規写真登録エラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      });
      if (result) {
        for (let i = 0; i < this.details.length; i++) {
          if (this.details[i].uploadFile.length !== 0) {
            await s3.putObject({
              Bucket: s3Bucket,
              Key: this.putImgKey + this.detailPhotoName[i] + this.extension,
              Body: this.details[i].uploadFile,
              ContentType: this.imageType
            }).promise().then(response => {
              console.log(response)
              console.log("putImgKey: "+ this.putImgKey)
              if (response.length !== 0) {
                result = true
                return result
              } else {
                result = false
                return result
              }
            }).catch(error => {
              result = false
              console.log(error)
              this.$swal({
                icon: 'error',
                html: `付帯情報の写真アップロードに失敗しました`,
                title: '付帯情報写真登録エラー:' + error,
                confirmButtonText: "閉じる"
              })
              return
            });
            await s3.putObject({
              Bucket: s3Bucket,
              Key: this.imgURL + this.detailPhotoName[i] + this.extension,
              Body: this.details[i].uploadFile,
              ContentType: this.imageType
            }).promise().then(response => {
              console.log(response)
              console.log("imgURL: "+ this.imgURL)
              if (response.length !== 0) {
                result = true
                return result
              } else {
                result = false
                return result
              }
            }).catch(error => {
              result = false
              console.log(error)
              this.$swal({
                icon: 'error',
                html: `付帯情報の写真アップロードに失敗しました`,
                title: '付帯情報写真登録エラー:' + error,
                confirmButtonText: "閉じる"
              })
              return
            });
            await s3.putObject({
              Bucket: s3Bucket,
              Key: this.resizeImgKey + this.detailPhotoName[i] + this.extension,
              Body: this.details[i].uploadFile,
              ContentType: this.imageType
            }).promise().then(response => {
              console.log(response)
              console.log("resizeImgKey: "+ this.resizeImgKey)
              if (response.length !== 0) {
                result = true
                return result
              } else {
                result = false
                return result
              }
            }).catch(error => {
              result = false
              console.log(error)
              this.$swal({
                icon: 'error',
                html: `付帯情報の写真アップロードに失敗しました`,
                title: '付帯情報写真登録エラー:' + error,
                confirmButtonText: "閉じる"
              })
              return
            });
          }
        }
      }
      if (result) {
        let detail = {
          params: {
            apiname: "postPhoto",
            type: "newfacility",
            uid: sessionStorage.getItem('userId'),
            photoDatas: {
              0: {
                position: 0,
                imgName: this.mainPhotoName + this.extension,
                releaseTo: this.photo.daterange.start,
                releaseFrom: this.photo.daterange.end,
                photographerID: sessionStorage.getItem('userId'),
                photoData: this.photo.shootDate,
                nextShootingDate: this.photo.nextShoot,
                shootingSeaaon: this.photo.season,
                shootingTime: this.photo.time,
                note: this.photo.remarks
              }
            },
            facilityData: {
              type: "newall",
              uid: sessionStorage.getItem('userId'),
              ClassificationID: this.typeID,
              tags: this.tagID,
              trainIDs: this.routeID,
              stationID: this.stationID,
              master: {
                facilityName: this.facility.facilityName,
                address: this.facility.address
              },
              details: {
                0: {
                  facilityDetailName: this.facility.facilityName,
                  lat: this.facility.lat,
                  lon: this.facility.lng,
                  cor: this.facility.correctionValue,
                  example: 0,
                  note: this.facility.remarks
                }
              }
            }
          }
        }
        for (let i = 0; i < this.details.length; i++) {
          if (this.details[i].uploadFile.length !== 0) {
            detail['params']['facilityData']['details'][i + 1] = {
              facilityDetailName: this.details[i].detailName,
              lat: this.details[i].lat,
              lon: this.details[i].lng,
              cor: this.details[i].correctionValue,
              example: 1,
              note: this.details[i].remarks
            }
          }
        }
        for (let i = 0; i < this.details.length; i++) {
          if (this.details[i].uploadFile.length !== 0) {
            detail['params']['photoDatas'][i + 1] = {
              position: i + 1,
              imgName: this.detailPhotoName[i] + this.extension,
              releaseTo: this.details[i].daterange.start,
              releaseFrom: this.details[i].daterange.end,
              photographerID: sessionStorage.getItem('userId'),
              photoData: this.details[i].shootDate,
              nextShootingDate: this.details[i].nextShoot,
              shootingSeaaon: this.details[i].season,
              shootingTime: this.details[i].time,
              note: this.details[i].remarks
            }
          }
        }
        await this.axios.post('/api/v1/api', detail).then(response => {
          try {
            console.log(response)
            if (response.data.body.status === 200) {
              message.push(response.data.body.response.message)
              for (let i = 0; i < message.length; i++) {
                this.$swal({
                  allowOutsideClick: false,
                  icon: 'success',
                  html: `<b>${message[i]}<br></b>`,
                  showCancelButton: true,
                  confirmButtonText: 'Topページに戻る',
                  cancelButtonText: '続けて写真を登録する'
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.push('/mypage')
                  } else {
                    this.photo.image = '';
                    this.photo.imageName = '';
                    this.photo.uploadFile = '';
                    this.photo.shootDate = '';
                    this.photo.nextShoot = '';
                    this.photo.daterange.start = '';
                    this.photo.daterange.end = '';
                    this.photo.season = '';
                    this.photo.time = '';
                    this.facility.lat = '';
                    this.facility.lng = '';
                    this.facility.address = '';
                    this.facility.newFacility = false,
                        this.facility.selectFacility = false,
                        this.facility.isTrain = false,
                        this.facility.middleFacility = false
                    this.$bvModal.hide('PhotoConfirmModal')
                    this.$router.go({
                      path: '/photoform',
                      force: true
                    })
                  }
                })
              }
            } else {
              message.push(response.data.body.response.message)
              for (let i = 0; i < message.length; i++) {
                this.$swal({
                  icon: 'error',
                  html: `<b>${message[i]}<br></b>`,
                  confirmButtonText: "閉じる"
                })
              }
            }
          } catch (error) {
            console.log(error)
            this.$swal({
              icon: 'error',
              title: '新規写真登録ステータスエラー:' + error,
              confirmButtonText: "閉じる"
            })
            return
          }
        }).catch(error => {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '新規写真登録レスポンスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        })
      }
    },
    //写真情報更新
    async updatePhotoData(updateData) {
      let message = []
      let updateDetail = {
        params: {
          apiname: "putPhoto",
          uid: sessionStorage.getItem('userId'),
          photoVals: {}
        }
      }
      for (let i = 0; i < updateData.length; i++) {
        updateDetail['params']['photoVals'][updateData[i].inPhotoID] = {
          nextShootingDate: updateData[i].dtNextShootingDate,
        }
      }
      await this.axios.post('/api/v1/api', updateDetail).then(response => {
        try {
          if (response.data.body.status === 200) {
            message.push(response.data.body.response.message)
            for (let i = 0; i < message.length; i++) {
              this.$swal({
                allowOutsideClick: false,
                icon: 'success',
                html: `<b>${message[i]}</b><br>`,
                confirmButtonText: '閉じる',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.checkUpdate = []
                }
              })
            }
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '写真情報更新ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '写真情報更新レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //写真申請中取得
    getApprovalPhotoList(offset, limit) {
      this.message.approvalPhoto = ''
      this.axios.post('/api/v1/api', {
        params: {
          apiname: "getApprovalList",
          type: "adminPhotoAll",
          limit: limit,
          offset: offset
        }
      }).then(response => {
        console.log(response)
        try {
          if (response.data.body.response !== "[]") {
            this.photoDataCount = response.data.body.others.count
            this.photoDatas = JSON.parse(response.data.body.response)
            this.displayPhoto = this.photoDatas.length
            if (this.photoDatas.length !== 0) {
              for (let i = 0; i < this.photoDatas.length; i++) {
                this.photoDatas[i]['replacePhotoFlag'] = false
                if (this.photoDatas[i].boExample === 0) {
                  this.photoDatas[i].vcFacilityDetailName = '代表情報'
                }
                if (this.photoDatas[i].inParentClassID != 0) {
                  this.photoDatas[i].vcClassificationName = this.photoDatas[i].vcMiddleClassName
                }
                this.photoDatas[i].dtPhotoDate = this.photoDatas[i].dtPhotoDate.split('T')[0];
                this.photoDatas[i].dtNextShootingDate = this.photoDatas[i].dtNextShootingDate.split('T')[0];
                this.photoDatas[i]['oldvcStorage'] = this.imgURL + this.photoDatas[i].vcStorage
                this.photoDatas[i]['oldinApproval'] = this.photoDatas[i].inApproval
                this.photoDatas[i].vcStorage = this.resizeImgURL + this.photoDatas[i].vcStorage
                if (!this.photoDatas[i].oldUrl) {
                  this.photoDatas[i].oldUrl = '/static/images/noimage.jpg'
                } else {
                  this.photoDatas[i].oldUrl = this.imgURL + this.photoDatas[i].oldUrl
                }
              }
            } else {
              this.message.approvalPhoto = "現在、承認待ちの写真がありません。"
              this.photoDataCount = 0
              this.displayPhoto = 0
            }
          } else {
            let currentPage = Number(sessionStorage.getItem('photoApprovalCurrentPage'))
            if (currentPage == 2) {
              currentPage = Number(1)
              this.currentPage = currentPage
              sessionStorage.setItem('photoApprovalCurrentPage', currentPage)
              offset = 0
              sessionStorage.setItem('photoApprovalOffset', offset)
              this.getApprovalPhotoList(offset, limit)
            } else if (currentPage == 1) {
              this.message.approvalPhoto = '現在、承認待ちの写真がありません。'
              this.photoDataCount = 0
              this.displayPhoto = 0
            } else if (currentPage == 0) {
              this.message.approvalPhoto = '現在、承認待ちの写真がありません。'
              this.photoDataCount = 0
              this.displayPhoto = 0
            } else {
              currentPage = Number(currentPage - 1)
              this.currentPage = currentPage
              sessionStorage.setItem('photoApprovalCurrentPage', currentPage)
              offset = (currentPage - 1) * limit;
              console.log(offset)
              sessionStorage.setItem('photoApprovalOffset', offset)
              this.getApprovalPhotoList(offset, limit)
            }
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '写真申請中ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '写真申請中レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //施設申請中取得
    getApprovalFacilityList(offset, limit) {
      this.oldFacilityData = []
      this.message.approvalFacility = ''
      this.axios.post('/api/v1/api', {
        params: {
          apiname: "getApprovalList",
          type: "adminFacilityAll",
          limit: limit,
          offset: offset
        }
      }).then(response => {
        try {
          console.log(response)
          if (response.data.body.response !== "[]") {
            this.facilityDataCount = response.data.body.others.count
            this.facilityDatas = JSON.parse(response.data.body.response)
            this.displayfacility = this.facilityDatas.length
            if (this.facilityDatas.length !== 0) {
              for (let i = 0; i < this.facilityDatas.length; i++) {
                this.facilityDatas[i]['active'] = false
                if (this.facilityDatas[i].inParentClassID != 0) {
                  this.facilityDatas[i].vcClassificationName = this.facilityDatas[i].vcMiddleClassName
                }
                this.facilityDatas[i].dtCreate = this.facilityDatas[i].dtCreate.split('T')[0];
                if (this.facilityDatas[i].vcStorage !== null) {
                  this.facilityDatas[i].vcStorage = this.resizeImgURL + this.facilityDatas[i].vcStorage
                } else {
                  this.facilityDatas[i].vcStorage = this.noImage
                }
                this.oldFacilityData.push({
                  vcFacilityDetailName: this.facilityDatas[i].vcFacilityDetailName,
                  inFacilityDetailsID: this.facilityDatas[i].inFacilityDetailsID
                })
              }
            } else {
              this.message.approvalFacility = '現在、承認待ちの施設がありません。'
              this.facilityDataCount = 0
              this.displayfacility = 0
            }
          } else {
            let currentPage = Number(sessionStorage.getItem('facilityapprovalCurrentPage'))
            if (currentPage == 2) {
              currentPage = Number(1)
              this.currentPage = currentPage
              sessionStorage.setItem('facilityapprovalCurrentPage', currentPage)
              offset = 0
              sessionStorage.setItem('facilityapprovalOffset', offset)
              this.getApprovalFacilityList(offset, limit)
            } else if (currentPage == 1) {
              this.message.approvalFacility = '現在、承認待ちの施設がありません。'
              this.facilityDataCount = 0
              this.displayfacility = 0
            } else if (currentPage == 0) {
              this.message.approvalFacility = '現在、承認待ちの施設がありません。'
              this.facilityDataCount = 0
              this.displayfacility = 0
            } else {
              currentPage = Number(currentPage - 1)
              this.currentPage = currentPage
              sessionStorage.setItem('facilityapprovalCurrentPage', currentPage)
              offset = (currentPage - 1) * limit;
              sessionStorage.setItem('facilityapprovalOffset', offset)
              this.getApprovalFacilityList(offset, limit)
            }
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設申請中ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '施設申請中レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //写真承認機能
    async putPhotoData(approvalList) {
      let message = []
      let putPhotoApproval = {
        params: {
          apiname: "putApproval",
          type: "photo",
          uid: sessionStorage.getItem('userId'),
          tiRole: sessionStorage.getItem('role'),
          photoApprovals: {}
        }
      }
      for (let i = 0; i < approvalList.length; i++) {
        putPhotoApproval['params']['photoApprovals'][approvalList[i].inPhotoID] = {
          approval: approvalList[i].inApproval,
          comment: approvalList[i].txAdComment,
        }
        // console.log(putPhotoApproval)
      }
      await this.axios.post('/api/v1/api', putPhotoApproval).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            message.push(response.data.body.response.message)
            for (let i = 0; i < message.length; i++) {
              this.$swal({
                allowOutsideClick: false,
                icon: 'success',
                html: `<b>${message[i]}</b><br>`,
                confirmButtonText: '閉じる',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.checkUpdate = []
                  this.$router.go({
                    path: this.$router.currentRoute.path,
                    force: true
                  })
                }
              })
            }
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '写真承認ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '写真承認レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //施設承認機能
    async putFacilityData(approvalList, facility) {
      let message = []
      let putFacilityApproval = {
        params: {
          apiname: "putApproval",
          type: "facilityDetail",
          uid: sessionStorage.getItem('userId'),
          tiRole: sessionStorage.getItem('role'),
          detailApprovals: {}
        }
      }
      for (let i = 0; i < facility.length; i++) {
        if (facility[i].boExample === 0) {
          putFacilityApproval['params']['detailApprovals'][facility[i].inFacilityDetailsID] = {
            approval: facility[i].inApproval,
            comment: facility[i].txAdComment,
            detailName: facility[i].vcFacilityDetailName,
            master: {
              masterID: facility[i].inFacilityID,
              facilityName: facility[i].vcFacilityDetailName
            }
          }
        } else {
          putFacilityApproval['params']['detailApprovals'][facility[i].inFacilityDetailsID] = {
            approval: facility[i].inApproval,
            comment: facility[i].txAdComment,
            detailName: facility[i].vcFacilityDetailName
          }
        }
      }
      for (let i = 0; i < approvalList.length; i++) {
        putFacilityApproval['params']['detailApprovals'][approvalList[i].inFacilityDetailsID] = {
          approval: approvalList[i].inApproval,
          comment: approvalList[i].txAdComment,
        }
      }
      await this.axios.post('/api/v1/api', putFacilityApproval).then(response => {
        try {
          console.log(response)
          if (response.data.body.status === 200) {
            message.push(response.data.body.response.message)
            for (let i = 0; i < message.length; i++) {
              this.$swal({
                allowOutsideClick: false,
                icon: 'success',
                html: `<b>${message[i]}</b><br>`,
                confirmButtonText: '閉じる',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.checkUpdate = []
                  this.$router.go({
                    path: this.$router.currentRoute.path,
                    force: true
                  })
                }
              })
            }
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設承認ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '施設承認レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //新規区分追加
    async newClass(classificationName, parentClassID, middleClassName, sort) {
      let message = []
      let newClassification = {
        params: {
          apiname: "putClassTag",
          uid: sessionStorage.getItem('userId'),
          role: sessionStorage.getItem('role'),
          classValues: [{
            type: 'insert',
            className: classificationName,
            parentClassID: parentClassID,
            middleClassName: middleClassName,
            sort: sort
          }]
        }
      }
      await this.axios.post('/api/v1/api', newClassification).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            this.$swal({
              allowOutsideClick: false,
              icon: 'success',
              html: `<b>区分の追加が完了しました</b><br>`,
              confirmButtonText: '閉じる',
            }).then((result) => {
              if (result.isConfirmed) {
                this.MainClassAdd = ""
                this.facilityTypes = []
                this.middleFacilityTypes = []
                this.middleClassAdd = ''
                this.middleClassList = []
                this.getFacilityType()
                return
              }
            })
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '新規区分ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '新規区分レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //区分削除
    deleteClass(deleteClassIDs, deleteTagIDs) {
      let message = []
      let deleteClass = {
        params: {
          apiname: "deleteClass",
          role: sessionStorage.getItem('role'),
          tags: deleteTagIDs,
          classIDs: deleteClassIDs
        }
      }
      this.axios.post('/api/v1/api', deleteClass).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            this.$swal({
              allowOutsideClick: false,
              icon: 'success',
              html: `<b>区分の削除が完了しました</b><br>`,
              confirmButtonText: '閉じる',
            }).then((result) => {
              if (result.isConfirmed) {
                this.MainClassAdd = ""
                this.facilityTypes = []
                this.middleFacilityTypes = []
                this.middleClassAdd = ''
                this.middleClassList = []
                this.getFacilityType()
                return
              }
            })
          } else if (response.data.body.status === 400) {
            //エラー時該当施設の一覧表示：区分の更新ができるようにand仕分けしない施設をその他区分に更新
            this.$swal({
              icon: 'error',
              title: "登録済み施設があります",
              confirmButtonText: "閉じる"
            })
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          this.$swal({
            icon: 'error',
            title: '区分削除ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '区分削除レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //新規タグ追加
    newTag(classID, tagAdd, sort) {
      let message = []
      let newTagParams = {
        params: {
          apiname: "putClassTag",
          uid: sessionStorage.getItem('userId'),
          role: sessionStorage.getItem('role'),
          tagValues: [{
            type: 'insert',
            classID: classID,
            tagName: tagAdd,
            sort: sort
          }]
        }
      }
      this.axios.post('/api/v1/api', newTagParams).then(response => {
        try {
          if (response.data.body.status === 200) {
            this.$swal({
              allowOutsideClick: false,
              icon: 'success',
              html: `<b>タグの追加が完了しました</b><br>`,
              confirmButtonText: '閉じる',
            }).then((result) => {
              if (result.isConfirmed) {
                this.tagAdd = ""
                this.facilityTags = []
                this.approvalTagList = []
                this.userPushTagList = []
                this.getTagAdmin(this.MainClassID)
                return
              }
            })
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '新規タグステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '新規タグレスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //タグ削除
    deleteTags(deleteTagIDs) {
      let message = []
      let deleteClass = {
        params: {
          apiname: "deleteClass",
          role: sessionStorage.getItem('role'),
          tags: deleteTagIDs
        }
      }
      this.axios.post('/api/v1/api', deleteClass).then(response => {
        try {
          if (response.data.body.status === 200) {
            this.$swal({
              allowOutsideClick: false,
              icon: 'success',
              html: `<b>タグの削除が完了しました</b><br>`,
              confirmButtonText: '閉じる',
            }).then((result) => {
              if (result.isConfirmed) {
                this.facilityTags = []
                this.approvalTagList = []
                this.userPushTagList = []
                this.getTagAdmin(this.MainClassID)
                return
              }
            })
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: 'タグ削除ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: 'タグ削除レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //タグ承認
    approvalTag(vals) {
      let message = []
      let appTagParams = {
        params: {
          apiname: "putClassTag",
          uid: sessionStorage.getItem('userId'),
          role: sessionStorage.getItem('role'),
          tagValues: vals
        }
      }
      this.axios.post('/api/v1/api', appTagParams).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            this.$swal({
              allowOutsideClick: false,
              icon: 'success',
              html: `<b>タグの承認が完了しました</b><br>`,
              confirmButtonText: '閉じる',
            }).then((result) => {
              if (result.isConfirmed) {
                this.facilityTags = []
                this.approvalTagList = []
                this.userPushTagList = []
                this.getTagAdmin(this.MainClassID)
                return
              }
            })
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: 'タグ承認ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: 'タグ承認レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //区分ソート更新
    sortClassApi(type, vals) {
      let message = []
      let putSortParams = {}
      if (type == "class") {
        putSortParams = {
          params: {
            apiname: "putClassTag",
            uid: sessionStorage.getItem('userId'),
            role: sessionStorage.getItem('role'),
            classValues: vals
          }
        }
      } else if (type == "tag") {
        putSortParams = {
          params: {
            apiname: "putClassTag",
            uid: sessionStorage.getItem('userId'),
            role: sessionStorage.getItem('role'),
            tagValues: vals
          }
        }
      }
      this.axios.post('/api/v1/api', putSortParams).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            this.$swal({
              allowOutsideClick: false,
              icon: 'success',
              html: `<b>並び替えが完了しました</b><br>`,
              confirmButtonText: '閉じる',
            }).then((result) => {
              if (result.isConfirmed) {
                this.MainClassAdd = ""
                this.facilityTypes = []
                this.middleFacilityTypes = []
                this.middleClassAdd = ''
                this.middleClassList = []
                this.mainClassSortflag = 0
                this.middleClassSortflag = 0
                this.tagSortflag = 0
                this.getFacilityType()
                return
              }
            })
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '区分ソートステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '区分ソートレスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //施設情報削除
    facilityDelete() {
      this.$swal({
        title: '施設削除',
        text: "施設を削除してもよろしいでしょうか？",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '確定',
        cancelButtonText: 'キャンセル',
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/api/v1/api', {
            params: {
              apiname: "putdelete",
              type: "facility",
              uid: this.userID,
              role: this.role,
              facilityID: this.facilityID
            }
          }).then(response => {
            try {
              let message = response.data.body.response.message
              if (response.data.body.result) {
                this.$swal({
                  title: message,
                  allowOutsideClick: false,
                  icon: 'success',
                  confirmButtonText: '<a style="text-decoration:none; color:black;" href="/mypage">Topページに戻る</a>',
                })
              } else {
                this.$swal({
                  icon: 'error',
                  title: message,
                  confirmButtonText: "閉じる"
                })
              }
            } catch (error) {
              console.log(error)
              this.$swal({
                icon: 'error',
                title: '施設削除ステータスエラー:' + error,
                confirmButtonText: "閉じる"
              })
              return
            }
          }).catch(error => {
            console.log(error)
            this.$swal({
              icon: 'error',
              title: '施設削除レスポンスエラー:' + error,
              confirmButtonText: "閉じる"
            })
            return
          })
        }
      })
    },
    //施設情報の削除
    deleteFacilityData(facilityID) {
      let message = []
      this.axios.post('/api/v1/api', {
        params: {
          apiname: "putdelete",
          type: "facility",
          uid: Number(this.userID),
          role: Number(this.role),
          facilityID: facilityID
        }
      }).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            message.push(response.data.body.response.message)
            for (let i = 0; i < message.length; i++) {
              this.$swal({
                allowOutsideClick: false,
                icon: 'success',
                html: `<b>${message[i]}</b><br>`,
                confirmButtonText: '閉じる',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push('/facilitylist')
                }
              })
            }
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              html: `<b>${message[0]}</b><br>`,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設情報ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '施設情報レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //追加情報の削除
    deleteDetailsData(detailsID) {
      let message = []
      this.axios.post('/api/v1/api', {
        params: {
          apiname: "putdelete",
          type: "facilityDetails",
          uid: Number(this.userID),
          role: Number(this.role),
          facilityDetailIDs: detailsID
        }
      }).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            message.push(response.data.body.response.message)
            for (let i = 0; i < message.length; i++) {
              this.$swal({
                allowOutsideClick: false,
                icon: 'success',
                html: `<b>${message[i]}</b><br>`,
                confirmButtonText: '閉じる',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push('/facilitylist')
                }
              })
            }
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              html: `<b>${message[0]}</b><br>`,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '追加情報ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '追加情報レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //既存施設への追加情報写真の登録
    async addPhotoDetails(facilityID, facilityName, s3, s3Bucket) {
      console.log('addPhotoDetails')
      if (this.photo.uploadFile.type === "image/jpeg") {
        this.extension = ".jpeg"
        this.imageType = "image/jpeg"
      } else if (this.photo.uploadFile.type === "image/jpg") {
        this.extension = ".jpg"
        this.imageType = "image/jpg"
      }
      let date = moment().format("YYYYMMDD_HH:mm:SS")
      this.mainPhotoName = [facilityName.replace(/\+|\]|\[|\?|%|#|\s+|\//gi, '_'), date, 1, ].join('_')
      let message = []
      let result = false
      await s3.putObject({
        Bucket: s3Bucket,
        Key: this.putImgKey + this.mainPhotoName + this.extension,
        Body: this.photo.uploadFile,
        ContentType: this.imageType
      }).promise().then(response => {
        console.log(response)
        console.log("putImgKey: "+ this.putImgKey)
        if (response.length !== 0) {
          result = true
          message.push("写真のアップロードが完了しました")
          return result
        } else {
          result = false
          message.push('写真のアップロードに失敗しました')
          return result
        }
      }).catch(error => {
        result = false
        console.log(error)
        this.$swal({
          icon: 'error',
          html: `写真のアップロードに失敗しました`,
          title: '写真登録エラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      });
      await s3.putObject({
        Bucket: s3Bucket,
        Key: this.imgURL + this.mainPhotoName + this.extension,
        Body: this.photo.uploadFile,
        ContentType: this.imageType
      }).promise().then(response => {
        console.log(response)
        if (response.length !== 0) {
          result = true
          message.push("写真のアップロードが完了しました")
          return result
        } else {
          result = false
          message.push('写真のアップロードに失敗しました')
          return result
        }
      }).catch(error => {
        result = false
        console.log(error)
        this.$swal({
          icon: 'error',
          html: `写真のアップロードに失敗しました`,
          title: '写真登録エラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      });
      await s3.putObject({
        Bucket: s3Bucket,
        Key: this.resizeImgKey + this.mainPhotoName + this.extension,
        Body: this.photo.uploadFile,
        ContentType: this.imageType
      }).promise().then(response => {
        console.log(response)
        if (response.length !== 0) {
          result = true
          message.push("写真のアップロードが完了しました")
          return result
        } else {
          result = false
          message.push('写真のアップロードに失敗しました')
          return result
        }
      }).catch(error => {
        result = false
        console.log(error)
        this.$swal({
          icon: 'error',
          html: `写真のアップロードに失敗しました`,
          title: '写真登録エラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      });
      if (result) {
        let detail = {
          params: {
            apiname: "postPhoto",
            type: "addDetails",
            uid: sessionStorage.getItem('userId'),
            photoDatas: {
              0: {
                position: 0,
                imgName: this.mainPhotoName + this.extension,
                releaseTo: this.photo.daterange.start,
                releaseFrom: this.photo.daterange.end,
                photographerID: sessionStorage.getItem('userId'),
                photoData: this.photo.shootDate,
                nextShootingDate: this.photo.nextShoot,
                shootingSeaaon: this.photo.season,
                shootingTime: this.photo.time,
                note: this.photo.remarks
              }
            },
            facilityData: {
              type: "addDetail",
              uid: sessionStorage.getItem('userId'),
              masterID: facilityID,
              details: {
                0: {
                  facilityDetailName: this.facility.facilityName,
                  lat: this.facility.lat,
                  lon: this.facility.lng,
                  cor: this.facility.correctionValue,
                  example: 1,
                  note: this.facility.remarks
                }
              }
            }
          }
        }
        await this.axios.post('/api/v1/api', detail).then(response => {
          console.log(response)
          try {
            if (response.data.body.status === 200) {
              message.push(response.data.body.response.message)
              this.message.photo = message
              this.$swal({
                allowOutsideClick: false,
                icon: 'success',
                html: `<b>${message[0]}</b><br><b>${message[1]}</b>`,
                showCancelButton: true,
                confirmButtonText: 'Topページに戻る',
                cancelButtonText: '続けて写真を登録する'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push('/mypage')
                } else {
                  this.photo.image = '';
                  this.photo.imageName = '';
                  this.photo.uploadFile = '';
                  this.photo.shootDate = '';
                  this.photo.nextShoot = '';
                  this.photo.daterange.start = '';
                  this.photo.daterange.end = '';
                  this.photo.season = '';
                  this.photo.time = '';
                  this.facility.lat = '';
                  this.facility.lng = '';
                  this.facility.address = '';
                  this.addExistingDetailFlag = false
                }
              })
            } else {
              message.push(response.data.body.response.message)
              this.message.photo = message
              this.$swal({
                icon: 'error',
                title: message,
                confirmButtonText: "閉じる"
              })
            }
          } catch (error) {
            console.log(error)
            this.$swal({
              icon: 'error',
              title: '追加写真ステータスエラー:' + error,
              confirmButtonText: "閉じる"
            })
            return
          }
        }).catch(error => {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '追加写真レスポンスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        })
      } else {
        this.$swal({
          icon: 'error',
          title: '写真のアップロードに失敗しました',
          confirmButtonText: "閉じる"
        })
      }
    },
    //施設新規登録
    async postNewFacility() {
      let detail = {
        params: {
          apiname: "postFacility",
          type: "newall",
          uid: Number(sessionStorage.getItem('userId')),
          ClassificationID: this.typeID,
          tags: this.tagID,
          trainIDs: this.routeID,
          stationID: this.stationID,
          master: {
            facilityName: this.facility.facilityName,
            address: this.facility.address
          },
          details: {
            0: {
              facilityDetailName: this.facility.facilityName,
              lat: this.facility.lat,
              lon: this.facility.lng,
              cor: this.facility.correctionValue,
              example: 0,
              note: this.facility.remarks
            }
          }
        }
      }
      for (let i = 0; i < this.details.length; i++) {
        if (this.details[i].detailName !== '' && this.details[i].detailActive) {
          detail['params']['details'][i + 1] = {
            facilityDetailName: this.details[i].detailName,
            lat: this.details[i].lat,
            lon: this.details[i].lng,
            cor: this.details[i].correctionValue,
            example: 1,
            note: ""
          }
        }
      }
      await this.axios.post('/api/v1/api', detail).then(response => {
        try {
          let message = response.data.body.response.message
          console.log(response)
          if (response.data.body.result) {
            this.$swal({
              title: message,
              allowOutsideClick: false,
              icon: 'success',
              confirmButtonText: 'Topページに戻る',
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/mypage')
              }
            })
          } else {
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設新規登録ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '施設新規登録レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //登録済み施設取得
    getApprovalRegisteredList(lat, lon) {
      this.approvalRegistLists = []
      this.message.approval = ''
      this.axios.post('/api/v1/api', {
        params: {
          apiname: "getApprovalList",
          type: "registeredFacility",
          lat: lat,
          lon: lon
        }
      }).then(response => {
        try {
          if (response.data.body.status === 200) {
            this.approvalRegistLists = response.data.body.response
            if (this.approvalRegistLists.length !== 0) {
              for (let i = 0; i < this.approvalRegistLists.length; i++) {
                this.approvalRegistLists[i]['active'] = false
                if (this.approvalRegistLists[i].boExample === 0) {
                  this.approvalRegistLists[i].vcFacilityDetailName = '代表情報'
                }
              }
            }
          } else if (response.data.body.status === 204) {
            this.message.approval = '新規登録施設です。'
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設新規登録ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '施設新規登録レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //施設名の更新
    async putFacilityName(facility) {
      let facilityName = {
        params: {
          apiname: "putFacility",
          uid: Number(sessionStorage.getItem('userId')),
        }
      }
      for (let i = 0; i < facility.length; i++) {
        if (facility[i].boExample === 0) {
          facilityName['params']['facilityID'] = facility[i].inFacilityID
          facilityName['params']['facilitymaster'] = {}
          facilityName['params']['facilitymaster']['facilityName'] = facility[i].vcFacilityDetailName
          facilityName['params']['facilityDetail'] = {}
          facilityName['params']['facilityDetail'][i + 1] = {}
          facilityName['params']['facilityDetail'][i + 1]['detailID'] = facility[i].inFacilityDetailsID
          facilityName['params']['facilityDetail'][i + 1]['facilityDetailName'] = facility[i].vcFacilityDetailName
        } else {
          facilityName['params']['facilityID'] = facility[i].inFacilityID
          facilityName['params']['facilityDetail'] = {}
          facilityName['params']['facilityDetail'][i + 1] = {}
          facilityName['params']['facilityDetail'][i + 1]['detailID'] = facility[i].inFacilityDetailsID
          facilityName['params']['facilityDetail'][i + 1]['facilityDetailName'] = facility[i].vcFacilityDetailName
        }
      }
      await this.axios.post('/api/v1/api', facilityName).then(response => {
        console.log(response)
      }).catch(error => {
        console.log(error)
      })
    },
    //マイページデータ取得
    getMyPage(type, selectType, offset, limit) {
      this.axios.post('/api/v1/api', {
        params: {
          apiname: "getMyPage",
          type: type,
          uid: sessionStorage.getItem('userId'),
          selectType: selectType,
          limit: limit,
          offset: offset
        }
      }).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            this.allListCount = response.data.body.listCount
            if (type === 'all') {
              if (response.data.body.allCount.length !== 0) {
                this.allCount = response.data.body.allCount
                for (let i = 0; i < this.allCount.length; i++) {
                  this.totalPostCount += this.allCount[i].totalCount
                }
                if (this.allCount[0].regist_time !== this.toDay) {
                  this.monthPostCount = 0
                } else {
                  this.monthPostCount = this.allCount[0].totalCount
                }
              } else {
                this.monthPostCount = 0
              }
              if (response.data.body.applyingCount.length !== 0) {
                this.applyingCount = response.data.body.applyingCount
                for (let i = 0; i < this.applyingCount.length; i++) {
                  this.totalApplyingCount += this.applyingCount[i].totalCount
                }
                if (this.applyingCount[0].regist_time !== this.toDay) {
                  this.monthApplyingCount = 0
                } else {
                  this.monthApplyingCount = this.applyingCount[0].totalCount
                }
              } else {
                this.monthApplyingCount = 0
              }
            }
            this.photoLists = JSON.parse(response.data.body.photoList)
            this.displayListCount = this.photoLists.length
            for (let i = 0; i < this.photoLists.length; i++) {
              if (this.photoLists[i].boExample === 0) {
                this.photoLists[i].vcFacilityDetailName = '代表情報'
              }
              this.photoLists[i].dtPhotoDate = this.photoLists[i].dtPhotoDate.split('T')[0];
              this.photoLists[i].dtNextShootingDate = this.photoLists[i].dtNextShootingDate.split('T')[0];
              this.photoLists[i].vcStorage = this.resizeImgURL + this.photoLists[i].vcStorage
              if (this.photoLists[i].inApproval === 0) {
                this.photoLists[i].inApproval = "未承認";
              } else if (this.photoLists[i].inApproval === 1) {
                this.photoLists[i].inApproval = "承認";
              } else if (this.photoLists[i].inApproval === 2) {
                this.photoLists[i].inApproval = "差し戻し";
              }
            }
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: 'マイページステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: 'マイページレスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //自動距離測該当施設の取得
    async getAutoDistanceList(column, sort) {
      this.searchLists = []
      this.distanceList = []
      this.message.distance = ''
      this.message.error = ''
      this.message.searchName = ''
      if (this.facility.types.length !== 0) {
        if (this.facility.distance !== '') {
          await this.axios.post('/api/v1/api', {
            params: {
              apiname: "getFacilityList",
              type: "automaticDistance",
              lat: this.facility.lat,
              lon: this.facility.lng,
              classIDs: this.facility.types,
              distance: this.facility.distance,
              sortColumn: column,
              sort: sort
            }
          }).then(response => {
            console.log(response)
            try {
              if (response.data.body.status === 200) {
                this.distanceList = response.data.body.response
                for (let i = 0; i < this.distanceList.length; i++) {
                  this.distanceList[i]['activeFlag'] = false
                  this.distanceList[i].distance = '約' + Math.floor(this.distanceList[i].distance * 1000) + 'm'
                  if (this.distanceList[i].boExample === 0) {
                    this.distanceList[i].vcFacilityDetailName = '代表情報'
                  }
                  if (this.distanceList[i].vcCorrectionVal === '') {
                    this.distanceList[i].vcCorrectionVal = 0
                  }
                }
              } else {
                this.message.distance = '該当施設が見つかりません。'
              }
            } catch (error) {
              console.log(error)
              this.$swal({
                icon: 'error',
                title: '該当施設ステータスエラー:' + error,
                confirmButtonText: "閉じる"
              })
              return
            }
          }).catch(error => {
            console.log(error)
            this.$swal({
              icon: 'error',
              title: '該当施設レスポンスエラー:' + error,
              confirmButtonText: "閉じる"
            })
            return
          })
        } else {
          this.message.error = '絞込み距離を指定してください。'
        }
      } else {
        this.message.error = '施設区分を選択してください。'
      }
    },
    //施設名で検索
    async searchFacilityName() {
      this.distanceList = []
      this.searchLists = []
      this.message.error = ''
      this.message.distance = ''
      this.message.searchName = ''
      if (this.searchName !== '') {
        await this.axios.post('/api/v1/api', {
          params: {
            apiname: "getFacilityList",
            type: "nameSearch",
            name: this.searchName
          }
        }).then(response => {
          console.log(response)
          try {
            if (response.data.body.status === 200) {
              this.searchLists = response.data.body.response
              for (let i = 0; i < this.searchLists.length; i++) {
                this.searchLists[i]['activeFlag'] = false
                if (this.searchLists[i].doLatitude === '' || this.searchLists[i].doLatitude === null || this.searchLists[i].doLatitude === 0) {
                  this.searchLists[i].doLatitude = this.facility.lat
                }
                if (this.searchLists[i].doLongitude === '' || this.searchLists[i].doLongitude === null || this.searchLists[i].doLongitude === 0) {
                  this.searchLists[i].doLongitude = this.facility.lng
                }
                if (this.searchLists[i].vcCorrectionVal === '') {
                  this.searchLists[i].vcCorrectionVal = 0
                }
                if (this.searchLists[i].boExample === 0) {
                  this.searchLists[i].vcFacilityDetailName = '代表情報'
                }
              }
            }
          } catch (error) {
            console.log(error)
            this.$swal({
              icon: 'error',
              title: '施設名ステータスエラー:' + error,
              confirmButtonText: "閉じる"
            })
            return
          }
        }).catch(error => {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設名レスポンスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        })
      } else {
        this.message.searchName = '施設名を入力してください。'
      }
    },
    //施設緯度経度の更新api
    async putLatLng(mainID, selectID, selectLat, selectLng, selectCol) {
      let message = []
      let newLatLng = {
        params: {
          apiname: 'putFacility',
          uid: Number(this.userID),
          facilityID: mainID,
          facilityDetail: {
            0: {
              detailID: selectID,
              doLatitude: selectLat,
              doLongitude: selectLng,
              vcCorrectionVal: String(selectCol)
            }
          }
        }
      }
      await this.axios.post('/api/v1/api', newLatLng).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            message.push(response.data.body.response.message)
            for (let i = 0; i < message.length; i++) {
              this.$swal({
                allowOutsideClick: false,
                icon: 'success',
                html: `<b>${message[i]}</b><br>`,
                confirmButtonText: '閉じる',
              })
            }
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '緯度経度ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '緯度経度レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //投稿写真数累計取得
    async getPhotoSummaryList() {
      await this.axios.post('/api/v1/api', {
        params: {
          apiname: "getSummaryList"
        }
      }).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            this.summaryLists = response.data.body.summaryList
            this.userLists = response.data.body.userList
            for (let i = 0; i < this.userLists.length; i++) {
              if (this.userLists[i].vcUserLName === this.summaryLists[this.userLists[i].inUserID].userName) {
                this.summaryLists[this.userLists[i].inUserID]['userID'] = this.userLists[i].inUserID
              }
            }
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '投稿写真数ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '投稿写真数レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //施設追加情報追加
    postAddDetails() {
      let detail = {
        params: {
          apiname: "postFacility",
          type: "addDetail",
          uid: this.userID,
          masterID: this.facility.id,
          details: {}
        }
      }
      for (let i = 0; i < this.addDetails.length; i++) {
        if (this.addDetails[i].detailName !== '' && this.addDetails[i].addDetailActive === true) {
          detail['params']['details'][i] = {
            facilityDetailName: this.addDetails[i].detailName,
            lat: this.addDetails[i].lat,
            lon: this.addDetails[i].lng,
            cor: this.addDetails[i].correctionValue,
            example: 1,
            note: this.addDetails[i].remarks
          }
        } else if (this.addDetails[i].detailName === '' && this.addDetails[i].addDetailActive === true) {
          this.$swal({
            icon: 'error',
            title: '新規追加情報の名称が未入力です。',
            confirmButtonText: "閉じる"
          })
          return
        }
      }
      this.axios.post('/api/v1/api', detail).then(response => {
        let message = response.data.body.response.message
        console.log(response)
        try {
          if (response.data.body.result) {
            this.$swal({
              allowOutsideClick: false,
              icon: 'success',
              html: `<b>${message}</b><br>`,
              confirmButtonText: '閉じる',
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/facilityDetail/' + this.facility.id)
              }
            })
          } else {
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設追加情報ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '施設追加情報レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //施設情報更新API
    async updateFacilityData(facility, newDetails, oldDetails) {
      for (let i = 0; i < this.addDetails.length; i++) {
        if (this.addDetails[i].addDetailActive === true && this.addDetails[i].detailName !== '') {
          this.postAddDetails()
        } else if (this.addDetails[i].detailName === '' && this.addDetails[i].addDetailActive === true) {
          this.$swal({
            icon: 'error',
            title: '新規追加情報の名称が未入力です。',
            confirmButtonText: "閉じる"
          })
          return
        }
      }
      let message = []
      let oldDetail = {
        params: {
          apiname: 'putFacility',
          uid: Number(this.userID),
          facilityID: this.facility.id,
        }
      }
      if (facility.facilityType !== '') {
        oldDetail['params']['classID'] = facility.facilityType
      }
      if (facility.detailsID !== '' || newDetails[0].detailsActive === true) {
        oldDetail['params']['facilityDetail'] = {}
        if (facility.facilityName !== '' || facility.lat !== '' || facility.lng !== '' || facility.correctionValue !== '' || facility.note !== '') {
          oldDetail['params']['facilityDetail'][0] = {}
          oldDetail['params']['facilityDetail'][0]['detailID'] = facility.detailsID
          if (facility.facilityName !== '') {
            oldDetail['params']['facilityDetail'][0]['facilityDetailName'] = facility.facilityName
          }
          if (facility.lat !== '') {
            oldDetail['params']['facilityDetail'][0]['doLatitude'] = facility.lat
          }
          if (facility.lng !== '') {
            oldDetail['params']['facilityDetail'][0]['doLongitude'] = facility.lng
          }
          if (facility.correctionValue !== '') {
            oldDetail['params']['facilityDetail'][0]['correctionVal'] = facility.correctionValue
          }
          if (facility.note !== '') {
            oldDetail['params']['facilityDetail'][0]['txNote'] = facility.note
          }
        }
        if (newDetails[0].detailsActive) {
          for (let i = 0; i < newDetails.length; i++) {
            if (newDetails[i].detailName !== oldDetails[i].vcFacilityDetailName || newDetails[i].lat !== oldDetails[i].doLatitude || newDetails[i].lng !== oldDetails[i].doLongitude || newDetails[i].correctionValue !== oldDetails[i].vcCorrectionVal || newDetails[i].remarks !== oldDetails[i].txNote) {
              oldDetail['params']['facilityDetail'][i + 1] = {
                'detailID': newDetails[i].id,
                'facilityDetailName': newDetails[i].detailName,
                'doLatitude': newDetails[i].lat,
                'doLongitude': newDetails[i].lng,
                'correctionVal': newDetails[i].correctionValue,
                'txNote': newDetails[i].remarks
              }
            }
          }
        }
      }
      if (this.facility.tags.length !== 0) {
        oldDetail['params']['newTags'] = this.facility.tags
      }
      if (this.oldTags.length !== 0) {
        oldDetail['params']['deleteTags'] = this.oldTags
      }
      if (this.facility.trainInfo !== 0) {
        let newTrain = []
        for (let val in this.facility.trainInfo) {
          newTrain.push({
            'trainID': this.facility.trainInfo[val].iRouteNo,
            'stationID': this.facility.trainInfo[val].iStationNo
          })
          oldDetail['params']['newTrains'] = newTrain
        }
      }
      if (this.oldTrain !== 0) {
        let oldTrain = []
        for (let val in this.oldTrain) {
          oldTrain.push({
            'trainID': this.oldTrain[val].iRouteNo,
            'stationID': this.oldTrain[val].iStationNo
          })
          oldDetail['params']['deleteTrains'] = oldTrain
        }
      }
      //施設代表情報アップデート確認
      if (facility.facilityName !== '' || facility.address !== '') {
        oldDetail['params']['facilitymaster'] = {}
        if (facility.facilityName !== '') {
          oldDetail['params']['facilitymaster']['facilityName'] = facility.facilityName
        }
        if (facility.address !== '') {
          oldDetail['params']['facilitymaster']['address'] = facility.address
        }
      }
      await this.axios.post('/api/v1/api', oldDetail).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            message.push(response.data.body.response.message)
            for (let i = 0; i < message.length; i++) {
              this.$swal({
                allowOutsideClick: false,
                icon: 'success',
                html: `<b>${message[i]}</b><br>`,
                confirmButtonText: '閉じる',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push('/facilityDetail/' + this.facility.id)
                }
              })
            }
          } else {
            message.push(response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '施設情報更新ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '施設情報更新レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //-----物件情報API-----↓
    //内観撮影リスト取得
    async getIntrospectionList(offset, limit) {
      this.message.search = ""
      let message = ''
      await this.axios.post('/api/v1/api', {
        params: {
          apiname: "getIntrospectionList",
          limit: limit,
          offset: offset
        }
      }).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            this.introspectionLists = JSON.parse(response.data.body.response)
            if (response.data.body.others.count !== 0) {
              this.totalIntrospection = response.data.body.others.count
              this.displayIntrospection = this.introspectionLists.length
              for (let i = 0; i < this.introspectionLists.length; i++) {
                for (let contact of this.contactApproval) {
                  if (contact.id == this.introspectionLists[i].tiApprovedFlag) {
                    this.introspectionLists[i].tiApprovedFlag = contact.value
                  }
                }
                if (this.introspectionLists[i].tiMaterialNo == null && this.introspectionLists[i].tiMaterialNo == '') {
                  this.introspectionLists[i].tiMaterialNo = ''
                } else if (this.introspectionLists[i].tiMaterialNo == 80 && this.introspectionLists[i].tiTypeCd == 2) {
                  this.introspectionLists[i].tiMaterialNo = '外構残し'
                } else if (this.introspectionLists[i].tiMaterialNo == 83 && this.introspectionLists[i].tiTypeCd == 2) {
                  this.introspectionLists[i].tiMaterialNo = '完成'
                } else {
                  this.introspectionLists[i].tiMaterialNo = ''
                }
                if (this.introspectionLists[i].vcFixNumber === null) {
                  this.introspectionLists[i].vcFixNumber = ''
                }
                if (this.introspectionLists[i].inUserID === null) {
                  this.introspectionLists[i].inUserID = ''
                }
                if (this.introspectionLists[i].txContactAddress === 'None') {
                  this.introspectionLists[i].txContactAddress = ''
                }
                if (this.introspectionLists[i].txKeyInformation === 'None') {
                  this.introspectionLists[i].txKeyInformation = ''
                }
                if (this.introspectionLists[i].txNote === 'None') {
                  this.introspectionLists[i].txNote = ''
                }
                if (this.introspectionLists[i].dtIntrospectionShootingDate !== null) {
                  this.introspectionLists[i].dtIntrospectionShootingDate = moment(this.introspectionLists[i].dtIntrospectionShootingDate).format('YYYY-MM-DD HH:mm')
                }
                if (this.introspectionLists[i].dtCreate !== null) {
                  this.introspectionLists[i].dtCreate = this.introspectionLists[i].dtCreate.split('T')[0];
                }
                if (this.introspectionLists[i].dtReform !== null) {
                  this.introspectionLists[i].dtReform = this.introspectionLists[i].dtReform.split('T')[0];
                  this.introspectionLists[i].dtReform = moment(this.introspectionLists[i].dtReform).format('YYYY年MM月')
                  if (this.introspectionLists[i].tiReformStateCd !== null) {
                    for (let t = 0; t < this.reformStateCode.length; t++) {
                      if (this.reformStateCode[t].id == this.introspectionLists[i].tiReformStateCd) {
                        this.introspectionLists[i].dtReform = this.introspectionLists[i].dtReform + this.reformStateCode[t].value
                      }
                    }
                  }
                } else {
                  this.introspectionLists[i].dtReform = '未確認'
                }
                if (this.introspectionLists[i].dtReformDay !== null) {
                  this.introspectionLists[i].dtReformDay = this.introspectionLists[i].dtReformDay.split('T')[0];
                }
              }
            } else {
              this.message.search = "該当の物件が見つかりませんでした。"
              this.totalIntrospection = 0
              this.displayIntrospection = 0
              this.showLoader = false
            }
          } else {
            message = response.data.body.response.message
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
            this.showLoader = false
            return
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '内観撮影ステータスエラー:' + error,
            html: `リストの取得に失敗しました。`,
            confirmButtonText: "閉じる"
          })
          this.showLoader = false
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '内観撮影レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        this.showLoader = false
        return
      })
      this.showLoader = false
    },
    //内観絞り込み表示API
    async searchIntrospectionList(offset, limit, searchTheme) {
      console.log(searchTheme)
      this.message.search = ''
      let message = ''
      let search = {
        params: {
          apiname: "getIntrospectionList",
          offset: offset,
          limit: limit
        }
      }
      //売主名検索
      if (searchTheme.companyName !== '') {
        search['params']["companySearch"] = searchTheme.companyName
      }
      //現場ID検索
      if (searchTheme.locationID !== '') {
        search['params']["locationID"] = searchTheme.locationID
      }
      //物件ID検索
      if (searchTheme.estateID !== '') {
        search['params']["estateID"] = searchTheme.estateID
      }
      //物件名検索
      if (searchTheme.estateName !== '') {
        search['params']["estateSearch"] = searchTheme.estateName
      }
      //住所検索
      if (searchTheme.address !== '') {
        search['params']["addSearch"] = searchTheme.address
      }
      //フリーワード検索
      if (searchTheme.freeWord !== '') {
        search['params']["freeSearch"] = searchTheme.freeWord
      }
      //ユーザー検索
      if (searchTheme.user !== '') {
        search['params']["inUserID"] = searchTheme.user
      }
      //種別検索
      if (searchTheme.estateKinds.length !== 0) {
        search['params']["kindCds"] = searchTheme.estateKinds
      }
      //内観撮影許可状況検索
      if (searchTheme.introFlag.length !== 0) {
        search['params']["introFlag"] = searchTheme.introFlag
      }
      //撮影予定日検索
      if (searchTheme.searchFrom !== '') {
        let searchFrom = moment(searchTheme.searchFrom)
        searchFrom = moment(searchFrom).format('YYYY-MM-DD');
        search['params']["fromIntroShootingTime"] = searchFrom
      }
      if (searchTheme.searchTo !== '') {
        let searchTo = moment(searchTheme.searchTo)
        searchTo = moment(searchTheme.searchTo).format('YYYY-MM-DD 23:59:59');
        search['params']["endIntroShootingTime"] = searchTo
      }
      //並び替え
      if (searchTheme.sort !== '' && searchTheme.sortType !== '') {
        search['params']["sortColumn"] = searchTheme.sort
        search['params']["sortType"] = searchTheme.sortType
      }
      await this.axios.post('/api/v1/api', search).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            this.introspectionLists = []
            this.introspectionLists = JSON.parse(response.data.body.response)
            if (response.data.body.others.count !== 0) {
              this.totalIntrospection = response.data.body.others.count
              this.displayIntrospection = this.introspectionLists.length
              for (let i = 0; i < this.introspectionLists.length; i++) {
                for (let contact of this.contactApproval) {
                  if (contact.id == this.introspectionLists[i].tiApprovedFlag) {
                    this.introspectionLists[i].tiApprovedFlag = contact.value
                  }
                }
                this.introspectionLists[i]['introspectionShootingDay'] = ''
                this.introspectionLists[i]['introspectionShootingTime'] = ''
                if (this.introspectionLists[i].tiMaterialNo == null && this.introspectionLists[i].tiMaterialNo == '') {
                  this.introspectionLists[i].tiMaterialNo = ''
                } else if (this.introspectionLists[i].tiMaterialNo == 80 && this.introspectionLists[i].tiTypeCd == 2) {
                  this.introspectionLists[i].tiMaterialNo = '外構残し'
                } else if (this.introspectionLists[i].tiMaterialNo == 83 && this.introspectionLists[i].tiTypeCd == 2) {
                  this.introspectionLists[i].tiMaterialNo = '完成'
                } else {
                  this.introspectionLists[i].tiMaterialNo = ''
                }
                if (this.introspectionLists[i].vcFixNumber === null) {
                  this.introspectionLists[i].vcFixNumber = ''
                }
                if (this.introspectionLists[i].inUserID === null) {
                  this.introspectionLists[i].inUserID = ''
                }
                if (this.introspectionLists[i].txContactAddress === 'None') {
                  this.introspectionLists[i].txContactAddress = ''
                }
                if (this.introspectionLists[i].txKeyInformation === 'None') {
                  this.introspectionLists[i].txKeyInformation = ''
                }
                if (this.introspectionLists[i].txNote === 'None') {
                  this.introspectionLists[i].txNote = ''
                }
                if (this.introspectionLists[i].dtIntrospectionShootingDate !== null) {
                  this.introspectionLists[i].dtIntrospectionShootingDate = moment(this.introspectionLists[i].dtIntrospectionShootingDate).format('YYYY-MM-DD HH:mm')
                }
                if (this.introspectionLists[i].dtCreate !== null) {
                  this.introspectionLists[i].dtCreate = this.introspectionLists[i].dtCreate.split('T')[0];
                }
                if (this.introspectionLists[i].dtReform !== null) {
                  this.introspectionLists[i].dtReform = this.introspectionLists[i].dtReform.split('T')[0];
                  this.introspectionLists[i].dtReform = moment(this.introspectionLists[i].dtReform).format('YYYY年MM月')
                  if (this.introspectionLists[i].tiReformStateCd !== null) {
                    for (let t = 0; t < this.reformStateCode.length; t++) {
                      if (this.reformStateCode[t].id == this.introspectionLists[i].tiReformStateCd) {
                        this.introspectionLists[i].dtReform = this.introspectionLists[i].dtReform + this.reformStateCode[t].value
                      }
                    }
                  }
                } else {
                  this.introspectionLists[i].dtReform = '未確認'
                }
                if (this.introspectionLists[i].dtReformDay !== null) {
                  this.introspectionLists[i].dtReformDay = this.introspectionLists[i].dtReformDay.split('T')[0];
                }
              }
            } else {
              this.message.search = "該当の物件が見つかりませんでした。"
              this.totalIntrospection = 0
              this.displayIntrospection = 0
              this.showLoader = false
            }
          } else {
            message = response.data.body.response.message
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
            this.showLoader = false
            return
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '内観絞り込みステータスエラー:' + error,
            html: `リストの取得に失敗しました。`,
            confirmButtonText: "閉じる"
          })
          this.showLoader = false
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '内観絞り込みレスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        this.showLoader = false
        return
      })
      this.showLoader = false
    },
    //内観写真情報更新API
    async putIntrospection(userID, type, estateVals, userName) {
      let message = ''
      await this.axios.post('/api/v1/api', {
        params: {
          apiname: "putIntrospection",
          uid: userID,
          type: type,
          estateVals: estateVals
        }
      }).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            if (type === 'introUser') {
              message = '担当情報の更新が完了しました。'
              this.$bvModal.hide('UpdateUserModal')
            } else {
              message = response.data.body.response.message
            }
            this.$swal({
              allowOutsideClick: false,
              icon: 'success',
              html: `<b>${message}</b><br>`,
              confirmButtonText: '閉じる',
            }).then((result) => {
              if (result.isConfirmed) {
                if (type === 'all') {
                  this.$router.go({
                    path: '/introspectionlist',
                    force: true
                  })
                } else {
                  for (let i = 0; i < this.introspectionLists.length; i++) {
                    for (let t = 0; t < estateVals.length; t++) {
                      if (this.introspectionLists[i].cEstateID == estateVals[t].estateID) {
                        this.introspectionLists[i].inUserID = estateVals[t].introuid
                        this.introspectionLists[i].vcUserLName = userName
                      }
                    }
                  }
                }
              }
            })
          } else {
            if (type === 'introUser') {
              message = '担当情報の更新に失敗しました。'
              this.$bvModal.hide('UpdateUserModal')
            } else {
              message = response.data.body.response.message
            }
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
            return
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '内観写真ステータスエラー:' + error,
            html: `物件のアップデートに失敗しました。`,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '内観写真レスポンスエラー:' + error,
          html: `物件のアップデートに失敗しました。`,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //撮影予定カレンダーの取得
    async getEstateCalendarList(startDay, userID) {
      let message = ''
      let search = {
        params: {
          apiname: "getEstateCalendarList",
          selectStartDate: startDay,
        }
      }
      //ユーザーIDでの絞り込み空の場合、値を投げず全ユーザー取得
      if (userID != '') {
        this.searchUserFlag = true
        search.params['uid'] = userID
      } else {
        this.searchUserFlag = false
      }
      await this.axios.post('/api/v1/api', search).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            this.overIntroCount = response.data.body.response.intro.overIntoroCount[0].count
            this.overexteriorCount = response.data.body.response.exterior.overExteriorCount[0].count
            this.calendar[0].notShoot = this.overIntroCount
            this.calendar[0].exteriorNotShot = this.overexteriorCount
            if (this.overIntroCount !== 0) {
              this.calendar[0].totalCount = this.overIntroCount
            } else {
              this.calendar[0].totalCount = ''
            }
            if (this.overexteriorCount !== 0) {
              this.calendar[0].exteriorTotalCount = this.overexteriorCount
            } else {
              this.calendar[0].exteriorTotalCount = ''
            }
            this.introNotShoot = JSON.parse(response.data.body.response.intro.introNotShotCount)
            this.introShotComp = JSON.parse(response.data.body.response.intro.introShotCompCount)
            this.introConfCompCount = JSON.parse(response.data.body.response.intro.introConfCompCount)
            this.exteriorNotShot = JSON.parse(response.data.body.response.exterior.exteriorNotShotCount)
            this.exteriorShotComp = JSON.parse(response.data.body.response.exterior.exteriorShotCompCount)
            this.exteriorConfCompCount = JSON.parse(response.data.body.response.exterior.exteriorConfCompCount)
            this.getIntroShootCalendar()
            this.getExteriorShootCalendar()
            this.showLoader = false
          } else {
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
            this.showLoader = false
            return
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '撮影予定ステータスエラー:' + error,
            html: `カレンダーの取得に失敗しました`,
            confirmButtonText: "閉じる"
          })
          this.showLoader = false
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '撮影予定レスポンスエラー:' + error,
          html: `カレンダーの取得に失敗しました`,
          confirmButtonText: "閉じる"
        })
        this.showLoader = false
        return
      })
    },
    //進捗管理物件一覧の取得
    async getEstateList(searchTheme, offset, limit, sort = null) {
      this.error.getEstate = ''
      this.estateCount = 0
      this.locationCount = 0
      this.estateTotalCount = 0
      this.estateDisplayCount = 0
      let search = {
        params: {
          apiname: "getEstateList",
          offset: offset,
          limit: limit
        }
      }
      // // 最大検索数
      // if(searchTheme.searchLimit !== ''){
      //   search.params['searchLimit'] = searchTheme.searchLimit
      // }
      //撮影予定日開始日
      if (searchTheme.searchFrom !== '') {
        let searchFrom = moment(searchTheme.searchFrom)
        searchFrom = moment(searchFrom).format('YYYY-MM-DD 00:00:00');
        search.params['selectStartDate'] = searchFrom
      }
      //撮影予定日終了日
      if (searchTheme.searchTo !== '') {
        let searchTo = moment(searchTheme.searchTo)
        searchTo = moment(searchTo).format('YYYY-MM-DD 23:59:59');
        search.params['selectEndDate'] = searchTo
      }
      //撮影状況で絞込
      if (searchTheme.progress.length !== 0) {
        search.params['inProgressID'] = searchTheme.progress
      }
      //内観or外観で絞込
      search.params['shootingType'] = [searchTheme.checkType]
      if (searchTheme.checkType == 0) {
        this.typeExteriorFlag = true
      } else {
        this.typeExteriorFlag = false
      }
      if (searchTheme.checkType == 1) {
        this.typeIntroFlag = true
      } else {
        this.typeIntroFlag = false
      }
      this.showTableHeader()
      //物件IDで絞込
      if (searchTheme.estateID !== '') {
        search.params['estateID'] = searchTheme.estateID
      }
      //物件名で絞込
      if (searchTheme.estateName !== '') {
        search.params['searchEstate'] = searchTheme.estateName
      }
      //現場IDで絞込
      if (searchTheme.locationID !== '') {
        search.params['locationID'] = searchTheme.locationID
      }
      //現場名で絞込
      if (searchTheme.locationName !== '') {
        search.params['searchLocation'] = searchTheme.locationName
      }
      //住所で絞込
      if (searchTheme.address !== '') {
        search.params['searchAddress'] = searchTheme.address
      }
      //企業名で絞込
      if (searchTheme.companyName !== '') {
        search.params['searchCompany'] = searchTheme.companyName
      }
      //物件状態で絞込
      if (searchTheme.statusCode.length !== 0) {
        search.params['estateStateCd'] = searchTheme.statusCode
      }
      //物件種別で絞込
      if (searchTheme.estateKinds.length !== 0) {
        search.params['estateKind'] = searchTheme.estateKinds
      }
      // 担当者で絞込
      if (searchTheme.user.length !== 0) {
        if (Array.isArray(searchTheme.user)) {
          let searchUser = []
          for (let allUser of searchTheme.user) {
            searchUser.push(allUser.inUserID)
            search.params['uid'] = searchUser
          }
        } else {
          search.params['uid'] = [searchTheme.user]
        }
      }
      // 備考で絞込
      if (searchTheme.note !== '') {
        search.params['searchRemarks'] = searchTheme.note
      }
      // 建築工程で絞込
      if (searchTheme.process.length != 0 && this.typeExteriorFlag) {
        search.params['process'] = searchTheme.process
      }
      // コンテンツタイプの切り替え
      search.params['changeContent'] = searchTheme.changeContent
      if (searchTheme.changeContent == 2) {
        search.params['locationList'] = ''
        this.locationListFlag = true
      } else {
        this.locationListFlag = false
      }
      //マイソク資料での絞り込み
      if (searchTheme.document.length != 0) {
        if (searchTheme.document.includes(1) && searchTheme.document.includes(2)) {
          search['params']["material"] = 0
        } else {
          if (searchTheme.document.includes(1)) {
            search['params']["material"] = 1
          }
          if (searchTheme.document.includes(2)) {
            search['params']["material"] = 2
          }
        }
      } else {
        search['params']["material"] = 0
      }
      //並び替え
      if (sort != null) {
        search['params']["sort"] = sort
      }
      //csv出力
      if (this.csvDataFlag) {
        search['params']["csvData"] = ''
      }
      await this.axios.post('/api/v1/api', search).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            if (!this.csvDataFlag) {
              this.estateLists = JSON.parse(response.data.body.response.list)
              if (this.estateLists.length !== 0) {
                this.estateCount = response.data.body.response.count[0].estateCount
                this.locationCount = response.data.body.response.count[0].locationCount
                // this.estateDisplayCount = this.estateLists.length
                for (let i = 0; i < this.estateLists.length; i++) {
                  if (this.estateLists[i].dtCreate !== null) {
                    this.estateLists[i].dtCreate = this.estateLists[i].dtCreate.split('T')[0];
                  } else {
                    this.estateLists[i].dtCreate = ''
                  }
                  if (this.estateLists[i].dtLastTime !== null) {
                    this.estateLists[i].dtLastTime = this.estateLists[i].dtLastTime.split('T')[0];
                  }
                  if (this.estateLists[i].dtShootingDate !== null) {
                    this.estateLists[i].dtShootingDate = moment(this.estateLists[i].dtShootingDate).format('YYYY-MM-DD HH:mm')
                  }
                  if (this.estateLists[i].dtShootingDate == '') {
                    this.estateLists[i].dtShootingDate = null
                  }
                  if (this.estateLists[i].vcStorage !== null) {
                    this.estateLists[i].vcStorage = this.pdfURL + this.estateLists[i].vcStorage
                  } else {
                    this.estateLists[i].vcStorage = this.noImage
                  }
                  this.estateLists[i]['introProcessType'] = ''
                  for (let material of this.estateLists[i].material) {
                    if (material.vcMaterialID !== null) {
                      material.vcMaterialID = this.pdfURL + material.vcMaterialID
                    }
                    if (material.tiMaterialNo == this.companyDocument) {
                      material['materialName'] = '資'
                    } else if (material.tiMaterialNo == this.flashReport) {
                      material['materialName'] = 'マ'
                    } else if (material.tiMaterialNo == this.wardMap) {
                      material['materialName'] = '区'
                    } else if (material.tiMaterialNo == this.guideMap) {
                      material['materialName'] = '案'
                    }
                    if (material.vcMaterialID != null && material.tiMaterialNo == 80) {
                      this.estateLists[i].introProcessType = '外構残し'
                    } else if (material.vcMaterialID != null && material.tiMaterialNo == 83) {
                      this.estateLists[i].introProcessType = '完成'
                    }
                  }
                }
                this.showLoader = false
              } else {
                this.error.getEstate = '該当の物件が見つかりませんでした。'
                this.estateCount = 0
                this.locationCount = 0
                this.estateTotalCount = 0
                this.estateDisplayCount = 0
                this.showLoader = false
                this.showLoaderSecond = false
                return
              }
            } else {
              let csvData = ''
              csvData = response.data.body.response.csvData
              this.csvDataFlag = false
              this.downloadCSV(csvData)
            }
          } else {
            this.$swal({
              icon: 'error',
              title: 'リストの取得に失敗しました。',
              confirmButtonText: "閉じる"
            })
            this.showLoader = false
            return
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '進捗管理ステータスエラー:' + error,
            html: `リストの取得に失敗しました`,
            confirmButtonText: "閉じる"
          })
          this.showLoader = false
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '進捗管理レスポンスエラー:' + error,
          html: `リストの取得に失敗しました`,
          confirmButtonText: "閉じる"
        })
        this.showLoader = false
        return
      })
      this.showLoader = false
      if (!this.csvDataFlag) {
        this.getEstateListSecond(searchTheme, offset, limit)
      }
    },
    async getEstateListSecond(searchTheme, offset, limit, sort = null) {
      this.addressLists = []
      let search = {
        params: {
          apiname: "getEstateListSecond",
          offset: offset,
          limit: limit
        }
      }
      //撮影予定日開始日
      if (searchTheme.searchFrom !== '') {
        let searchFrom = moment(searchTheme.searchFrom)
        searchFrom = moment(searchFrom).format('YYYY-MM-DD 00:00:00');
        search.params['selectStartDate'] = searchFrom
      }
      //撮影予定日終了日
      if (searchTheme.searchTo !== '') {
        let searchTo = moment(searchTheme.searchTo)
        searchTo = moment(searchTo).format('YYYY-MM-DD 23:59:59');
        search.params['selectEndDate'] = searchTo
      }
      //撮影状況で絞込
      if (searchTheme.progress.length !== 0) {
        search.params['inProgressID'] = searchTheme.progress
      }
      //内観or外観で絞込
      search.params['shootingType'] = [searchTheme.checkType]
      if (searchTheme.checkType == 0) {
        this.typeExteriorFlag = true
      } else {
        this.typeExteriorFlag = false
      }
      if (searchTheme.checkType == 1) {
        this.typeIntroFlag = true
      } else {
        this.typeIntroFlag = false
      }
      this.showTableHeader()
      //物件IDで絞込
      if (searchTheme.estateID !== '') {
        search.params['estateID'] = searchTheme.estateID
      }
      //物件名で絞込
      if (searchTheme.estateName !== '') {
        search.params['searchEstate'] = searchTheme.estateName
      }
      //現場IDで絞込
      if (searchTheme.locationID !== '') {
        search.params['locationID'] = searchTheme.locationID
      }
      //現場名で絞込
      if (searchTheme.locationName !== '') {
        search.params['searchLocation'] = searchTheme.locationName
      }
      //住所で絞込
      if (searchTheme.address !== '') {
        search.params['searchAddress'] = searchTheme.address
      }
      //企業名で絞込
      if (searchTheme.companyName !== '') {
        search.params['searchCompany'] = searchTheme.companyName
      }
      //物件状態で絞込
      if (searchTheme.statusCode.length !== 0) {
        search.params['estateStateCd'] = searchTheme.statusCode
      }
      //物件種別で絞込
      if (searchTheme.estateKinds.length !== 0) {
        search.params['estateKind'] = searchTheme.estateKinds
      }
      // 担当者で絞込
      if (searchTheme.user.length !== 0) {
        if (Array.isArray(searchTheme.user)) {
          let searchUser = []
          for (let allUser of searchTheme.user) {
            searchUser.push(allUser.inUserID)
            search.params['uid'] = searchUser
          }
        } else {
          search.params['uid'] = [searchTheme.user]
        }
      }
      // 備考で絞込
      if (searchTheme.note !== '') {
        search.params['searchRemarks'] = searchTheme.note
      }
      // 建築工程で絞込
      if (searchTheme.process.length != 0 && this.typeExteriorFlag) {
        search.params['process'] = searchTheme.process
      }
      // コンテンツタイプの切り替え
      if (searchTheme.changeContent == 2) {
        search.params['locationList'] = ''
        this.locationListFlag = true
      } else {
        this.locationListFlag = false
      }
      //マイソク資料での絞り込み
      if (searchTheme.document.length != 0) {
        if (searchTheme.document.includes(1) && searchTheme.document.includes(2)) {
          search['params']["material"] = 0
        } else {
          if (searchTheme.document.includes(1)) {
            search['params']["material"] = 1
          }
          if (searchTheme.document.includes(2)) {
            search['params']["material"] = 2
          }
        }
      } else {
        search['params']["material"] = 0
      }
      //並び替え
      if (sort != null) {
        search['params']["sort"] = sort
      }
      this.axios.post('/api/v1/api', search).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            let addressList = []
            addressList.push(response.data.body.response.addresList)
            addressList[0].filter(
                (val) => {
                  if (val.EstateCount != 0) {
                    this.addressLists.push(val)
                  }
                })
            this.showLoaderSecond = false
          } else {
            this.$swal({
              icon: 'error',
              title: 'リストの取得に失敗しました。',
              confirmButtonText: "閉じる"
            })
            this.showLoaderSecond = false
            return
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: 'getEstateListSecondステータスエラー:' + error,
            html: `リストの取得に失敗しました`,
            confirmButtonText: "閉じる"
          })
          this.showLoaderSecond = false
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: 'getEstateListSecondレスポンスエラー:' + error,
          html: `リストの取得に失敗しました`,
          confirmButtonText: "閉じる"
        })
        this.showLoaderSecond = false
        return
      })
    },
    //CSVエクスポート
    async getCsvExport(type) {
      await this.axios.post('/api/v1/api', {
        params: {
          apiname: "csvExport",
          type: type
        }
      }).then(response => {
        try {
          if (response.data.body.status === 200) {
            this.usersData = response.data.body.response
            let csvData = ''
            csvData = response.data.body.response.csvData
            this.downloadCsvExport(csvData)
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: 'CSV出力ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: 'CSV出力レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //進捗管理画面からの情報更新API
    async putEstateList(userID, estateVals, userName, estateID) {
      let message = ''
      await this.axios.post('/api/v1/api', {
        params: {
          apiname: "putEstateList",
          uid: userID,
          estateID: estateID,
          shootingPrpgress: estateVals
        }
      }).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            message = response.data.body.response.message
            this.$swal({
              allowOutsideClick: false,
              icon: 'success',
              html: `<b>${message}</b>`,
              confirmButtonText: '閉じる',
            }).then((result) => {
              if (result.isConfirmed) {
                this.$emit('afterUpdate', {
                  estateVals,
                  estateID
                })
                //this.$router.go({ path: '/progressmanagementlist' , force: true })
              }
            })
          } else {
            message = response.data.body.response.message
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
            return
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: 'putEstateListステータスエラー:' + error,
            html: `物件のアップデートに失敗しました。`,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: 'putEstateListレスポンスエラー:' + error,
          html: `物件のアップデートに失敗しました。`,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //物件詳細情報取得
    async getEstateDetail(locationID, shootingType, shootingDate, userID = null, progressID = null, shootingProgressID = null) {
      let search = {
        params: {
          apiname: "getEstateDetail",
          locationID: locationID,
          shootingType: shootingType,
          shootingDate: shootingDate,
        }
      }
      if (userID != null) {
        search.params['uid'] = userID
      }
      if (progressID != null) {
        search.params['progressID'] = progressID
      }
      if (shootingProgressID != null) {
        search.params['shootingPrpgressID'] = shootingProgressID
      }
      await this.axios.post('/api/v1/api', search).then(response => {
        try {
          if (response.data.body.status === 200) {
            console.log(response)
            //内観の場合
            if (shootingType == 1) {
              let exteriorPhotoDatas = JSON.parse(response.data.body.response.ExteriorPhoto)
              this.locationDatas = JSON.parse(response.data.body.response.list)
              for (let exteriorPhotoData of exteriorPhotoDatas) {
                if (exteriorPhotoData.vcStorage !== null) {
                  exteriorPhotoData.vcStorage = this.resizeImgURL + exteriorPhotoData.vcStorage
                } else {
                  exteriorPhotoData.vcStorage = this.noImage
                }
                if (exteriorPhotoData.photoDate !== null) {
                  exteriorPhotoData.photoDate = exteriorPhotoData.photoDate.split('T')[0]
                }
              }
              let tabIndex = 0
              for (let locationData of this.locationDatas) {
                if (locationData.vcMatterportURL == null) {
                  locationData.vcMatterportURL = ''
                }
                if (locationData.dtDeadlineMatterport == null) {
                  locationData.dtDeadlineMatterport = ''
                } else {
                  locationData.dtDeadlineMatterport = locationData.dtDeadlineMatterport.split('T')[0]
                }
                locationData['tabIndex'] = tabIndex
                tabIndex++
                locationData['photoRegisteredFlag'] = false
                for (let material of locationData.material) {
                  if (material.vcMaterialID !== null) {
                    // material.vcMaterialID = this.pdfURL + material.vcMaterialID
                    material['materialURL'] = this.pdfURL + material.vcMaterialID
                  }
                  if (material.tiMaterialNo == this.companyDocument) {
                    material['materialName'] = '社内資料'
                  } else if (material.tiMaterialNo == this.flashReport) {
                    material['materialName'] = 'マイソク'
                  } else if (material.tiMaterialNo == this.wardMap) {
                    material['materialName'] = '区割り図'
                  } else if (material.tiMaterialNo == this.guideMap) {
                    material['materialName'] = '案内図'
                  }
                }
                locationData.material = locationData.material.filter(
                    (val) => {
                      return !(val.vcMaterialID === null)
                    })
                if (exteriorPhotoDatas.length !== 0) {
                  locationData.material.unshift(exteriorPhotoDatas[0])
                }
              }
              this.createPhotoForms()
              this.showLoader = false
              //外観の場合
            } else if (shootingType == 0) {
              this.locationDatas = JSON.parse(response.data.body.response.list)
              if (this.locationDatas.length != 0) {
                for (let locationData of this.locationDatas) {
                  if (locationData.previousMaterial.length != 0) {
                    for (let lastPhotoData of locationData.previousMaterial) {
                      if (lastPhotoData.idMaterialID != null) {
                        console.log(lastPhotoData.idMaterialID)
                        lastPhotoData.idMaterialID = this.pdfURL + lastPhotoData.idMaterialID
                      }
                      if (lastPhotoData.dtPhotoDate != null) {
                        lastPhotoData.dtPhotoDate = lastPhotoData.dtPhotoDate.split('T')[0]
                      }
                    }
                  }
                  if (locationData.material.length != 0) {
                    for (let material of locationData.material) {
                      if (material.vcMaterialID !== null) {
                        // material.vcMaterialID = this.pdfURL + material.vcMaterialID
                        material['materialURL'] = this.pdfURL + material.vcMaterialID
                      }
                      if (material.tiMaterialNo == this.companyDocument) {
                        material['materialName'] = '社内資料'
                      } else if (material.tiMaterialNo == this.flashReport) {
                        material['materialName'] = 'マイソク'
                      } else if (material.tiMaterialNo == this.wardMap) {
                        material['materialName'] = '区割り図'
                      } else if (material.tiMaterialNo == this.guideMap) {
                        material['materialName'] = '案内図'
                      }
                    }
                    locationData.material = locationData.material.filter(
                        (val) => {
                          return !(val.vcMaterialID === null)
                        })
                  }
                  if (locationData.photo.length != 0 && progressID == 2) {
                    for (let photoData of locationData.photo) {
                      if (photoData.vcStorage != null) {
                        photoData.dtCreate = photoData.dtCreate.split('T')[0]
                        photoData.vcStorage = this.originalImgURL + this.shootTypeName + '/' + photoData.dtCreate + '/' + photoData.vcStorage
                      }
                    }
                    this.registeredPhoto = locationData.photo
                    this.estateID = locationData.cEstateID
                    this.registeredPhotoFlag = true
                  }
                }
                this.showLoader = false
              } else {
                this.$swal({
                  icon: 'error',
                  title: '外観詳細情報ステータスエラー',
                  html: `外観情報の取得に失敗しました。`,
                  confirmButtonText: "閉じる"
                })
                this.showLoader = false
                return
              }
            }
          } else {
            this.$swal({
              icon: 'error',
              title: '外観詳細情報レスポンスエラー:',
              html: `外観情報の取得に失敗しました。`,
              confirmButtonText: "閉じる"
            })
            this.showLoader = false
            return
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '物件情報ステータスエラー:' + error,
            html: `物件情報の取得に失敗しました。`,
            confirmButtonText: "閉じる"
          })
          this.showLoader = false
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '物件情報レスポンスエラー:' + error,
          html: `物件情報の取得に失敗しました。`,
          confirmButtonText: "閉じる"
        })
        this.showLoader = false
        return
      })
    },
    //物件の写真投稿【内観】
    async postEstatePhoto(sendPhotoDatas, estateID, userID, shootingID, estateName, estateData,s3, s3Bucket) {
      console.log('postEstatePhoto')
      // let message = ''
      let result = true
      for (let sendPhotoData of sendPhotoDatas) {
        for (let photoData of sendPhotoData) {
          if (photoData.photoID == '') {
            const base64 = require("urlsafe-base64")
            await s3.putObject({
              Bucket: s3Bucket,
              Key: this.putImgKey + photoData.imgName.replace(/\+|\]|\[|\?|%|#|\s+|\//gi, '_'),
              Body: base64.decode(photoData.image.src.replace("data:image/jpeg;base64,", "")),
              ContentType: photoData.imgType
            }).promise().then(response => {
              console.log(response)
              if (response.length !== 0) {
                result = true
                return
              } else {
                result = false
                return
              }
            }).catch(error => {
              console.log(error)
              result = false
              this.$swal({
                icon: 'error',
                html: `写真のアップロードに失敗しました`,
                title: '写真登録エラー:' + error,
                confirmButtonText: "閉じる"
              })
              return
            });
          }
        }
      }
      if (result) {
        this.postEstatePhotoDetail(sendPhotoDatas, estateID, userID, shootingID, estateName, estateData)
      }
    },
    //内観写真情報DB格納
    postEstatePhotoDetail(sendPhotoDatas, estateID, userID, shootingID, estateName, estateData) {
      let message = ''
      let photoDetail = {
        params: {
          apiname: "postPhoto",
          type: "estateInter",
          uid: userID,
          estateID: estateID,
          inShootingPrpgressID: shootingID,
          MatterportURL: estateData.matterportURL.replace(/\s+/g, ''),
          deadlineMatterport: estateData.deadlineMatterport,
          photoDatas: []
        }
      }
      for (let sendPhotoData of sendPhotoDatas) {
        for (let i = 0; i < sendPhotoData.length; i++) {
          photoDetail.params.photoDatas.push({
            interiorID: sendPhotoData[i].interiorID,
            order: sendPhotoData[i].orderNo,
            photoID: sendPhotoData[i].photoID,
            photoData: sendPhotoData[i].shootDate,
            imgName: sendPhotoData[i].imgName,
            otherName: sendPhotoData[i].othersName
          })
        }
      }
      console.log(photoDetail)
      this.axios.post('/api/v1/api', photoDetail).then(response => {
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            message = (response.data.body.response.message)
            this.$swal({
              allowOutsideClick: false,
              icon: 'success',
              position: 'top-end',
              html: `<b>${estateName}の${message}</b>`,
              showConfirmButton: false,
              toast: true,
            })
          } else {
            message = (response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: 'ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: 'レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
    //エリアリストの取得
    async getAreaList(offset, limit, searchTheme) {
      console.log(searchTheme)
      this.message.search = ''
      try {
        let search = {
          params: {
            apiname: "getAreaResponsibility",
            limit: limit,
            offset: offset
          }
        }
        if (typeof searchTheme != 'undefined') {
          //ユーザーでの絞り込み時
          if (searchTheme.user != '') {
            search.params['selectUID'] = searchTheme.user
          }
          //都道府県での絞り込み
          if (searchTheme.address != '') {
            search.params['selectPrefectureCd'] = searchTheme.address
          }
          //エリア状態での絞り込み
          if (searchTheme.settingStatus != 'all') {
            search.params['setting'] = searchTheme.settingStatus
          }
          let type = ''
          //内観or外観のタイプでの絞り込み
          if (searchTheme.selectType.length != 0) {
            if (searchTheme.selectType.length == 2) {
              type = 3
            } else if (searchTheme.selectType.includes(1)) {
              type = 1
            } else if (searchTheme.selectType.includes(2)) {
              type = 2
            }
            search.params['selectType'] = type
          } else {
            type = 0
            search.params['selectType'] = type
          }
        }
        await this.axios.post('/api/v1/api', search).then(response => {
          console.log(response)
          if (response.data.body.status == 200) {
            this.areaLists = response.data.body.areaList
            this.totalAreaList = response.data.body.count[0].Count
            this.displayAreaList = this.areaLists.length
            this.showLoader = false
          } else {
            this.totalAreaList = 0
            this.displayAreaList = 0
            this.message.search = response.data.body.response.message
            this.showLoader = false
          }
        }).catch(error => {
          console.log(error)
          this.showLoader = false
          this.$swal({
            icon: 'error',
            title: 'エリアリストレスポンスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        })
      } catch (error) {
        console.log(error)
        this.showLoader = false
        this.$swal({
          icon: 'error',
          title: 'エリアリストステータスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      }
    },
    //撮影エリア情報更新
    async putAreaListData(userID, putList, putType) {
      let message = ''
      try {
        await this.axios.post('/api/v1/api', {
          params: {
            apiname: "putAreaResponsibility",
            type: putType,
            uid: userID,
            putList: putList
          }
        }).then(response => {
          console.log(response)
          try {
            if (response.data.body.status == 200) {
              message = response.data.body.response.message
              this.$swal({
                allowOutsideClick: false,
                icon: 'success',
                html: `<b>${message}</b>`,
                confirmButtonText: '閉じる',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.go({
                    path: '/areamanagement',
                    force: true
                  })
                }
              })
            } else {
              message = response.data.body.response.error
              this.$swal({
                icon: 'error',
                title: message,
                confirmButtonText: "閉じる"
              })
              return
            }
          } catch (error) {
            console.log(error)
            this.$swal({
              icon: 'error',
              title: '撮影エリア情報ステータスエラー:' + error,
              confirmButtonText: "閉じる"
            })
            return
          }
        }).catch(error => {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '撮影エリア情報レスポンスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        })
      } catch (error) {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: 'ステータスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      }
    },
    //物件の写真投稿【外観】
    async postExteriorPhoto(sendPhotoDatas, estateID, userID, estateData, typeCD, s3, s3Bucket) {
      let result = true
      //マンションの場合
      try {
        console.log('postExteriorPhoto')
        if (estateData.isMansion) {
          //状態が変化なしじゃなかった場合
          if (estateData.process != 1) {
            for (let photoDatas of sendPhotoDatas) {
              for (let photoData of photoDatas) {
                if (photoData.photoID == '' && photoData.uploadFile.length != 0 && result == true) {
                  const base64 = require("urlsafe-base64")
                  await s3.putObject({
                    Bucket: s3Bucket,
                    Key: this.originResizeKey + photoData.uploadFileName.replace(/\+|\]|\[|\?|%|#|\s+|\//gi, '_'),
                    Body: base64.decode(photoData.image.src.replace("data:image/jpeg;base64,", "")),
                    ContentType: 'image/jpg'
                  }).promise().then(response => {
                    console.log(response)
                    if (response.length !== 0) {
                      result = true
                      return
                    } else {
                      result = false
                      return
                    }
                  }).catch(error => {
                    console.log(error)
                    result = false
                    this.$swal({
                      icon: 'error',
                      html: `写真のアップロードに失敗しました`,
                      title: '写真登録エラー:' + error,
                      confirmButtonText: "閉じる"
                    })
                    return
                  });
                }
              }
            }
          }
          //土地・戸建ての場合
        } else {
          //状態が変化なしじゃなかった場合
          if (sendPhotoDatas.length != 0 && estateData.process != 1) {
            for (let photoData of sendPhotoDatas) {
              if (photoData.photoID == '' && photoData.uploadFile.length != 0 && result == true) {
                const base64 = require("urlsafe-base64")
                await s3.putObject({
                  Bucket: s3Bucket,
                  Key: this.originResizeKey + photoData.uploadFileName.replace(/\+|\]|\[|\?|%|#|\s+|\//gi, '_'),
                  Body: base64.decode(photoData.image.src.replace("data:image/jpeg;base64,", "")),
                  ContentType: 'image/jpg'
                }).promise().then(response => {
                  console.log(response)
                  if (response.length !== 0) {
                    result = true
                    return
                  } else {
                    result = false
                    return
                  }
                }).catch(error => {
                  console.log(error)
                  result = false
                  this.$swal({
                    icon: 'error',
                    html: `写真のアップロードに失敗しました`,
                    title: '写真登録エラー:' + error,
                    confirmButtonText: "閉じる"
                  })
                  return
                });
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
        result = false
        this.$swal({
          icon: 'error',
          html: `写真のアップロードに失敗しました`,
          title: '写真登録エラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      }
      if (result) {
        //DBに格納処理実行
        this.postExteriorPhotoDetail(sendPhotoDatas, estateID, userID, estateData, typeCD)
      } else {
        this.$swal({
          icon: 'error',
          html: `写真のアップロードに失敗しました`,
          title: '写真登録エラー',
          confirmButtonText: "閉じる"
        })
      }
    },
    //外観写真情報DB格納
    postExteriorPhotoDetail(sendPhotoDatas, estateID, userID, estateData, typeCD) {
      console.log('postExteriorPhotoDetail')
      let message = ''
      let photoDetail = {
        params: {
          apiname: "postPhoto",
          type: "estateExterior",
          uid: Number(userID),
          estateID: estateID,
          inShootingPrpgressID: estateData.shootingProgressID,
          processID: estateData.process,
          typeCd: typeCD,
          nextShootingDate: estateData.nextShoot,
          oldProcessID: estateData.oldProcessID,
          propertyMasterID: estateData.propertyMasterID,
          photoDatas: []
        }
      }
      //マンションの場合
      if (estateData.isMansion) {
        for (let photoDatas of sendPhotoDatas) {
          for (let photoData of photoDatas) {
            if (photoData.uploadFlag) {
              photoDetail.params.photoDatas.push({
                order: photoData.orderNo,
                photoID: photoData.photoID,
                photoData: photoData.shootDate,
                imgName: photoData.uploadFileName,
                vcOthers: '',
                txNote: estateData.comment,
                mansionShootingType: photoData.process
              })
            }
          }
        }
        //土地戸建ての場合
      } else {
        for (let photoData of sendPhotoDatas) {
          if (photoData.uploadFlag) {
            photoDetail.params.photoDatas.push({
              order: photoData.orderNo,
              photoID: photoData.photoID,
              photoData: photoData.shootDate,
              imgName: photoData.uploadFileName,
              vcOthers: '',
              txNote: estateData.comment,
              mansionShootingType: 0
            })
          }
        }
      }
      console.log(photoDetail)
      this.axios.post('/api/v1/api', photoDetail).then(response => {
        console.log(response.data)
        console.log(response)
        try {
          if (response.data.body.status === 200) {
            message = (response.data.body.response.message)
            this.$swal({
              allowOutsideClick: false,
              icon: 'success',
              position: 'top-end',
              html: `<b>${estateData.estateName}の${message}</b>`,
              showConfirmButton: false,
              toast: true,
            })
          } else {
            message = (response.data.body.response.message)
            this.$swal({
              icon: 'error',
              title: message,
              confirmButtonText: "閉じる"
            })
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: '外観写真ステータスエラー:' + error,
            confirmButtonText: "閉じる"
          })
          return
        }
      }).catch(error => {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: '外観写真レスポンスエラー:' + error,
          confirmButtonText: "閉じる"
        })
        return
      })
    },
  }
}
